@charset "utf-8";

// @use "../global" as *;
@use 'sass:math';

@import "module/settings";
@import "module/normalize";
@import "module/old";


@import "module/wp";
@import "module/block";
@import "module/content";
@import "module/layout";
@import "module/widget";
@import "module/single";
@import "module/slider";
@import "module/common";

@import "auxiliary/badge";
@import "auxiliary/balloon";
@import "auxiliary/barchart";
@import "auxiliary/button";
@import "auxiliary/designlist";
@import "auxiliary/frame";
@import "auxiliary/heading";
@import "auxiliary/hr";
@import "auxiliary/qa";
@import "auxiliary/ranking";
@import "auxiliary/reviewbox";
@import "auxiliary/reviewtable";
@import "auxiliary/star";
@import "auxiliary/toggle";
@import "auxiliary/voice";



.margin0{
    margin:0 !important;
}

.padding0{
    padding:0 !important;
}

@media screen and (min-width:769px){
  .d_sp{
    display: none;
  }
}


/****************************************

          Clearfix

*****************************************/

#container{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    @include mq-down(md){
      overflow: hidden;
    }
}

#container:after,
.clearfix:after,
.row:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: " ";
    font-size: 0;
}

.row {
  margin-right: -10px;
  margin-left: -10px;
}

.content .row{
  vertical-align: middle;
}

.row.padding0 {
    margin: auto;
}

/****************************************

          effect
          
*****************************************/
.effect i {
  height: 120px;
  padding: 20px;
  font-size: 100px;
  transition: .8s;
} 

.effect div {
  margin: auto;
  transition: .8s;
  transition-delay: .8s;
}

/****************************************

          Layout

*****************************************/

#container,
.wrapper {
    margin: 0 auto;
    width: 100%;
}

/****************************************

          main

*****************************************/

#main-wrap {
    width: 90%;
    margin: 20px auto;
}

#main-wrap:after {
    clear: both;
    display: block;
    content: "";
}
#main-wrap #single-main.full,
#main-wrap #page-main.full {
    max-width: 969px;
    margin: 0 auto;
}

#main,
#sidebar{
    border-bottom: 1px solid transparent;
}

#main,#single-main,#page-main {
  width: 100%;
}

.single-post-main{
  padding:1em 40px;
  .widget {
    margin: 2em 0;
  }
  & > *{
    &:first-child{
      margin-top: 0;
    }
  }
}

.hentry .post-meta{
  display: inline-block;
  width: 100%;
}

.hentry .post-meta,.article_footer{
    padding: 1em 20px;
}

.hentry .post-meta-bottom{
  float: left;
  font-size: .85em;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.hentry .post-meta-bottom i{
  margin-right: 5px;
}


.hentry,#single-main .post-sub{
  background:#fff;
  position: relative;
}

.newpost_title:before {
  font-family: fontAwesome;
  content:"\f105";
    margin-right: 5px;
}

.newpost_title {
    font-size: 20px;
    padding: 5px 0;
    border-bottom: 3px solid #ccc;
    margin: 10px 0;
}

#single-main .post-sub .single_title:first-of-type{
  margin-top: 0;
}

#content_area {
    position: relative;
    display: flex;
    gap: 10px;
    .content_area_side{
        position:relative;
        width: 105px;
        @include mq-down(md){
            display: none;
        }
    }
    & > article.hentry{
        flex:1;
    }

    & > *{
      min-width: 0;
    }
    
}

#main-wrap.main_404 {
    width: 80%;
    max-width: 900px;
    margin: 0 auto;
}

@media screen and (max-width: 768px){
  .main_404 {
      width: 90%;
      margin: 0 auto;
  }

  .newpost_title:before {
    content:none;
  }
  
  .newpost_title {
    text-align:center;
  }
}

.space-del{
    letter-spacing: -.40em;
}


/****************************************

          header

*****************************************/

*:first-child+html #header > div{ /* IE7 */
  clear: both;
  zoom:1;
}


.header-wrap {
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 1000;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
}

.header-logo {
  position: relative;
    margin: 0 auto;
    width: 90%;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
}



#logo ,.logo{
    font-weight: 100;
    line-height: 1;
    margin-right: auto;
    display: flex;
    align-items: center;
    font-size: initial;
    margin-bottom: 0;
}

#logo a,.logo a{
  display: inline-block;
}


#logo img ,.logo img{
    height: 55px;
    width: auto;
    vertical-align: middle;
    margin: .5em 0;
    padding: .3em 0;
    object-fit: contain;
}

div.logo_title {
    font-size: 2em;
    font-weight: bold;
    padding: .7em 0;
  }

#nav_fixed div.logo_title {
    font-size: 2em;
    padding: .4em 0;
}

.header_small_menu #description {
    float: left;
    padding: 3px 0;
    font-size: 13px;
}


.header-left {
    width: 80%;
}

.header-left div{
  text-align:right;
  vertical-align:middle;
}

form.searchform{
  position: relative;
}

.searchform .s{
  background: #fff;
   padding: 11px 50px 11px 10px;
    width: 100%;
    font-size: 14px;
    border: 1px solid #ccc;
}
input[type="submit"].searchsubmit{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 45px;
  padding:0; 
  background: url(../images/search_w.png) no-repeat 50% 50%;
  background-size: 17px;
  background-color: #3c74ad;
  border-left:none;
  border-radius: 0 3px 3px 0;
  }

#sidebar .widget_search.widget .searchform{
  margin: -10px;
}

.header_small_menu .searchform {
    width: 180px;
    margin-left:5px;
}

.header_small_menu .searchform .s {
    padding: 2px 20% 2px 5%;
}

.header_small_menu .searchsubmit {
    padding: 1px 23px;
    height: auto;
}

.header_small_menu li a {
    padding: 3px !important;
}

.header_small_menu a:before{
  font-family: fontAwesome;
    content:"\f0da  ";
    margin-right: 3px;
}

.header_small_menu {
    padding: 5px;
    font-size: 12px;
    .menu_title{
      display:contents;
    }
}

.header_small_menu_right li,.header_small_menu_left li{
  display: inline-block;
  padding: 0 3px;
    vertical-align: middle;
}

.header_small_menu_right {
    float: right;
}

.header_small_menu .menu_desc {
    display: none;
}

.header_search {
    float: right;
}

.header_small_content {
    width: 90%;
    margin: 0 auto;
}

div.gsc-control-cse {
    padding: 0;
}
table.gsc-search-box,
table.gsc-search-box td,
table.gsc-search-box table,
table.gsc-above-wrapper-area-container,
td.gsc-result-info-container{
  padding: 0;
  border: none;
}
table.gsc-search-box input.gsc-input{
  vertical-align: middle;
}


@media screen and (max-width:959px){
  #logo ,.logo{
      margin:0 auto;
      text-align: center;
  }

  .header_small_menu{
    padding:0; 
  }

  .header_small_menu #description{
    float: none;
    text-align: center;
  }

  .header_small_menu .header_small_menu_right{
      display: none;
  }

  div.logo_title {
      font-size: 1.5em;
      padding: 18px 0;  
  }

  #nav_fixed div.logo_title {
      font-size: 1.3em;
      padding: 12px 0;
  }
}

@media screen and (max-width:768px){

    #logo,.logo{
        display: inline-block;
        width: 100%;
        padding-left: 55px;
        padding-right:55px;
        text-align: center;
    }

    #logo img .logo img{
        height: 3em;
        margin: .1em 0;
        padding: .5em;
    }

    .header-logo{
        width: 100%;
        padding: 0;
    }
}

.description_sp {
    font-size: .5em;
    text-align: center;
    padding: .3em 0;
}

@media screen and (min-width:768px){
  .description_sp{
    display: none;
  }
}


/****************************************

          Navigation

*****************************************/

.header-logo #nav ul,#nav_fixed #nav ul{
  float: right;
}

#onlynav ul {
  display: table;
}

.header-logo #nav ul,#onlynav ul,#nav_fixed #nav ul{
  position: relative;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  z-index: 999;
}
.header-logo #nav ul::after,#onlynav ul::after,#nav_fixed #nav ul::after {
  display: block;
  clear: both;
  content: '';
}
.header-logo #nav ul li,#onlynav ul li,#nav_fixed #nav ul li {
  position: relative;
  float: left;
}
.header-logo #nav ul li:not(:first-child),#onlynav ul li:not(:first-child),#nav_fixed #nav ul li:not(:first-child) {
  border-left: none;
}
.header-logo #nav ul li:hover,#onlynav ul li:hover,#nav_fixed #nav ul li:hover {
  background-color: rgba(255,255,255,.3);
}
.header-logo #nav ul li a{
  padding: 1.5em .8em;
}
#onlynav ul li a{
  padding: 1em 1.3em;
}
#nav_fixed #nav ul li a{
  padding: 1.4em .8em;
}
.header-logo #nav ul li a,#onlynav ul li a,#nav_fixed #nav ul li a {
  display: inline-block;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
  font-weight: bold;
  vertical-align: bottom;
  font-size: .9em;
}

.header-logo #nav ul li ul a, #onlynav ul li ul a, #nav_fixed #nav ul li ul a{
  font-size: .9em;
  padding: 1em;
}

.header-logo #nav ul ul, #onlynav ul ul, #nav_fixed #nav ul ul{
  position: absolute;
  top: 100%;
  left: 0px;
}

.header-logo #nav ul li:last-child ul,#onlynav ul li:last-child ul,#nav_fixed #nav ul li:last-child ul {
  right:0
}

.header-logo #nav ul ul li,#onlynav ul ul li,#nav_fixed #nav ul ul li {
  float: none;
  margin: 0;
}
.header-logo #nav ul ul li:not(:first-child),#onlynav ul ul li:not(:first-child),#nav_fixed #nav ul ul li:not(:first-child) {
  border-top: none;
}
.header-logo #nav ul ul ul,#onlynav ul ul ul,#nav_fixed #nav ul ul ul {
  position: absolute;
  top: 0px;
  left: 100%;
}

.header-logo #nav ul ul,#onlynav ul ul,#nav_fixed #nav ul ul {
  z-index: 999;
  min-width: 100%;
}

.header-logo #nav .menu_desc,#onlynav .menu_desc,#nav_fixed #nav .menu_desc {
    font-size: 10px;
    margin-top: 5px;
    opacity: 0.5;
    text-align: center;
    white-space: normal;
    overflow: hidden;
    padding: 0 .5em;
    height: 12px;
}

.header-logo #nav ul ul .menu_desc,#onlynav ul ul .menu_desc,#nav_fixed #nav ul ul .menu_desc {
    display: none;
}

@media screen and (max-width:959px){
      #nav,#onlynav{
        display:none !important;
    }
}

/****************************************

          scroll nav

*****************************************/


#scrollnav {
  position: relative;
  width: 100%;
  background: #ccc;
  overflow-x: auto;
  display: none;
  .menu_desc{
    display: none;
  }
  ul {
    margin: 0 auto;
    list-style: none;
    white-space: nowrap;
    text-align: center;
    display: flex;
    li {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      flex: 1 0 auto;
      a{
        font-size: .7em;
        color: #070f33;
        background: #fff;
        padding: 6px 8px;
        opacity: .8;
        letter-spacing: normal;
        margin-right: 1px;
        display: block;
        white-space: nowrap;
        font-weight: bold;
      }
      ul{
        display: none;
      }
    }
  }
  @include mq-down(lg){
      display: block;
  }
}


/****************************************

          fix header

*****************************************/

#nav_fixed {
    display: none;
    z-index: 10;
    &.fixed {
      top: 0;
      left: 0;
      width: 100%;
      position: fixed;
      display: block;
      z-index: 1000;
      box-shadow: 0px 3px 10px rgba(200,200,200,0.2);
  }  
}


@media screen and (max-width: 959px){

  #nav_fixed .header-logo{
    width: 100%;
    padding: 3px 0;
  }

  #nav_fixed #nav {
    display: none;
  }


  #nav_fixed .logo img {
      height: 46px;
      width: auto;
      padding: .3em;
  }
}

@media screen and (max-width: 599px){
  #nav_fixed .logo img {
    height: 32px;
  }
}


/****************************************

          page link 

*****************************************/

.page-link{
  margin: 1em 0;
  text-align: center;
}
.comment-page-link{
  margin: 0 0 3em;
}

.page-link span.paged,
.comment-page-link span,
.comment-page-link a{
    display: inline-block;
    margin-right: 3px;
    padding: 5px 15px;
    border-radius: 5px;
    background: #999;
    font-weight: bold;
    color: #eee;
}

.page-link a span.paged,
.comment-page-link a{
  background: #eee;
  color: #333;
  border-bottom: 3px solid #ddd;
}

.page-link a{
  border-bottom: none;
  color: #000;
}

.page-link a span.paged:hover,
.comment-page-link a:hover{
  background: #eef;
  color: #000;
}

.page-link a span.page-links_tp {
    padding: .8em 2.5em .8em 1.5em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: #eee;
    display: inline-block;
    color: #555;
    border-bottom: solid 3px #ddd;
    border-radius: 3px;
    font-weight: bold;
    text-shadow: 2px 2px 2px rgba(200, 200, 200, .5);
    position: relative;
}


.page-link a span.page-links_tp:after {
    content: "\f0da";
    position: absolute;
    right: 10px;
    font-family: fontAwesome;
}

@media screen and (max-width:768px){
  .page-link a span.page-links_tp {
      font-size: .8em;
  }
}

/****************************************

          header image area

*****************************************/

.header_img_inner_wrap > div {
    display: table-cell;
    vertical-align: middle;
}

.header-image{
    position: relative;
    overflow: hidden;
}

.header-image img {
    width: 100%;
    vertical-align: middle;
}

a.header-image_link:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: .4s;
}

.header-image:hover a.header-image_link:before {
    box-shadow: inset 0 0px 8px rgba(0,0,0,.4);
}

a.header-image_link {
    transition: .4s;
    position: relative;
    display: block;
    line-height: 0;
}

.custom-header-img{
    position: relative;
    padding: 3em 0;
    box-shadow:0 0px 20px 0 rgba(100,100,100,0.3) inset, 0px 0px 10px 0 rgba(100,100,100,0.2) inset;
}

.custom-header-img .header_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit:cover;';
}

.header_img_inner_wrap,.firstview_header_inner_content{
    position: relative;
    text-align: center;
}

.header_image_title {
    font-weight: bold;
    font-size: 35px;
    margin-bottom: 20px;
}

.header_inner_icon {
    display: table-cell;
    width: 40%;
}

.header_inner_icon img {
    object-fit: contain;
    font-family: 'object-fit:contain;';
    max-height: 350px;
}

.header_inner_text{
    display: table-cell;
    width: 60%;
    vertical-align: middle;
    padding: 0 10px;
}

.header_image_desc {
    margin-bottom: 25px;
}


@media screen and (max-width: 768px){
    .custom-header-img{
        height: auto;
        padding:2em 0;
    }

    .header_inner_icon img{
      padding:20px; 
    }

    .header_image_title{
        font-size: 1.5em;
    }

    .header_image_desc{
      font-size: .9em;
    }

    .header_img_inner_wrap,.firstview_header_inner_content{
        padding: 0;
        width: 90%;
    }

    .header_img_inner_wrap > div{
        display: block;
    }

    .header_inner_text,.header_inner_icon{
        width: 100%;
        display: block;
    }
}

/****************************************

          Layout list

*****************************************/

#main .page{
  overflow: hidden;
  margin-bottom: 59px;
  border-bottom: 1px solid transparent;
}

.wrap-post-title {
    padding: 10px 20px;
    font-size: 1.3em;
    margin-bottom: 10px;
    position: relative;
    font-weight: bold;
}

.widget .wrap-post-title {
    margin-top: 0px;
}

.wrap-post-title-inner {
    position: absolute;
    right: 20px;
    font-size: 0.7em;
    padding: 0.3em 0;
}

@media screen and (max-width:768px){
  .wrap-post-title{
    font-size: 1em;
  }
}


.post-box{
  width:100%;
  position:relative;
  display: inline-block;
}

.post-box-contents {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    transition: .4s;
}

.post-box .post-meta-all {
    overflow-y: hidden;
    padding:5px 15px;
    display: table-cell;
    vertical-align: top;
}

.post-box .post-title {
    font-weight: bold;
    font-size: 16px;
    padding:5px 0; 
    a{
      text-decoration: none;
    }
}

.post-box .post-date {
    font-size: 12px;
    position: absolute;
    bottom: 10px;
}
.post-box .post-substr {
    font-size: 13px;
    height: 4.5em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.post-box-contents .post-author {
    position: absolute;
    bottom: 5px;
    right: 15px;
}

.post_thumbnail{
    width: 280px;
    float: left;
}

.post-box-thumbnail{
    background: #eee;
    position: relative;
}
.post-box-thumbnail__wrap{
  overflow: hidden;
  position: relative;
}
.post-box-thumbnail__wrap:before{
    content: "";
    padding-top: 61.8%;
    display: block;
    height: 0;
}
.content .post-box-thumbnail__wrap img,
.post-box-thumbnail__wrap img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    object-fit:cover;
    font-family: 'object-fit:cover;';
    transition: .2s ease-in-out;
}

.post-box .post-cat {
    background: #777;
    padding: 2px 0;
    font-size: 0.6em;
    border-radius: 2px;
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
    font-weight: bold;
}

.post_thumbnail .post-cat,
.grid_post_thumbnail .post-cat{
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    word-break: break-all;
    overflow: hidden;
    max-width: 90%;
    a{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      word-break: break-all;
      overflow: hidden;
    }
}

.post-cat-sp {
    padding: 4px 5px;
    border-radius: 3px;
}

.post-box .post-tag {
    display: inline;
    font-size: 0.6em;
    vertical-align: middle;
}

.post-box .post-tag a{
    display: inline-block;
    background: #eee;
    color: #000;
    padding: 3px 8px;
    font-size: 10px;
    margin: 0 3px;
    border-radius: 2px;
    position: relative;
    z-index: 1;
}

.post-box .post-tag i{
  font-family: fontAwesome;
    content:"\f02b";
    margin-right: 5px;
    vertical-align: middle;
}

.post-cat a,.post-cat-sp a{
  color:#fff;
    padding: 5px 10px;
    position: relative;
    z-index: 1;
    text-decoration: none;
}


.post-author li{
  display: inline-block;
    vertical-align: middle;
    line-height: 1;
}

.single-post-date.published{
  color: #999;
}

.single-post-date,.post-meta-bottom span{
    margin-right: 8px;
}

.post-meta-bottom .post_reading_time:before {
    content: "\f017";
    font-family: fontAwesome;
}

.post-meta-bottom .post_views:before {
    content: "\f06e";
    font-family: fontAwesome;
}

.post-meta-bottom .post_views:after {
    content: "views";
    font-size: .9em;
}

li.post-author-name {
    line-height: 30px;
    margin-left: 5px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 7em;
    white-space: nowrap;
    a{
      position: relative;
      z-index: 1;
      white-space: nowrap;
      text-decoration: none;
    }
}

.post-author-thum img{
   -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid #eee;
}


.comment-num{ white-space: nowrap; }

.more-link{
  float: right;
}

@media screen and (min-width:1201px){
/*    .post-1 .post-title{
        font-size: 25px;
    }

    .post-1 .post-substr{
        font-size: 15px;
    }

    .post-1 .post-author {
        position: absolute;
        bottom: 0;
    }

    .post-1 .post_thumbnail img {
        width: 450px;
        height: 300px;
    }

    .post-1 .post-meta-all{
        min-height:300px;
    }*/
}

@media screen and (max-width:768px){
    /*** post ***/

    .post_thumbnail{
        width: 180px;
    }

    .post-title {
        font-weight: bold;
        font-size: 0.9em !important;
    }

    .sticky-post-box .post-title{
        font-size: 1em !important;
    }

    .post-box {
        width:100%;
    }

    .post-box .post-date{
      position: relative;
      bottom: 0;
      font-size: .6em;
    }

    .post-box .post-author,.post-meta .post-author,.grid_post-box .post-author{
        display: none;
    }

    .widgettitle{
        padding: 10px;
    }

    .post-box .post-cat{
      border-radius: 0;
      pointer-events: none;
    }

    .post-cat a, .post-cat-sp a{
      padding: 2px 5px;
      display: inline-block;
      pointer-events: none;
    }

}

@media screen and (max-width:599px){
    .post-substr{
        display: none !important;
    }

    .post-box{
      display: initial;
    }

    .post-box-contents.clearfix {
      padding: 5px;
      margin-bottom: 1px;
  }

    .post-box .post-meta-all{
      padding:0 10px; 
    }

    .post-box .post-cat{
        padding: 0;
        text-align: center;
    }

    .post-box .post-tag a{
      padding: 1px 5px;
      position: relative;
    }

    .post-box .post-title{
      padding:0;
      font-size: 0.8em;
    }

    .post_thumbnail{
        width: 105px;
    }

    .post-box .post-tag{
      display: none;
    }

    li.post-author-name{
      display: none;
    }

    .post-box-contents .post-meta-all{
      padding-bottom: 0 !important;
    }
}

/****************************************

          Layout Grid

*****************************************/

.wrap-grid-post-box{
    margin: -3px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
}

.grid_post-box{
    width: 33.33333%;
    position: relative;
    padding: 4px;
    letter-spacing: normal;
    vertical-align: top;
}

.grid_post-box:hover .post-box-contents{
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
}

.grid_post_thumbnail{
    width: 100%;
}

.grid_post-box .post-meta-all{
    padding: 10px;
}

.post-meta-all{
    word-wrap: break-word;
}

.grid_post-box .post-title{
    font-weight: bold;
    font-size: 1em;
    padding:5px 0; 
}


.grid_post-box .post-tag{
    display: inline;
}

.grid_post-box .post-tag a{
    display: inline-block;
    vertical-align: middle;
    background: #eee;
    color: #000;
    padding: 2px 8px;
    font-size: 0.6em;
    margin: 0 3px;
    border-radius: 2px;
    position: relative;
    z-index: 1;
}
.grid_post-box .post-cat{
    background: #999;
    padding: 2px 0;
    font-size: 0.6em;
    border-radius: 2px;
    display: inline-block;
    vertical-align: middle;
}

.grid_post-box .post-date {
    font-size: 12px;
    position: absolute;
    bottom: 13px;
    left: 15px;
}

.grid_post-box .post-substr{
    font-size: 12px;
}

.grid_post-box .post-author{
    position: absolute;
    bottom: 8px;
    right: 15px;
}

.grid_post-box.adbox{
  width: 100%;
}

@media screen and (max-width:1200px){
    .grid_post-box{width: 50%;}
}

@media screen and (max-width:959px){
    .grid_post-box{width: 33.33333%;}
}

@media screen and (max-width:768px){
    .grid_post-box{width: 50%;padding: 2px;}

    .grid_post-box .post-meta-all{
        padding:5px;
        position: relative;
    }

    .grid_post-box .post-date {
      font-size: .7em;
    }

  .grid_post-box .post-date:before{
      content:none;
  }

}

@media screen and (max-width:599px){

    .grid_post-box .post-title{
      padding:0; 
    }

    .grid_post-box .post-tag{
        display: none;
    }

    .grid_post-box .post-date{
      position: relative;
      bottom: 0;
      left: 5px;
    }

}

/****************************************

          Layout Mini

*****************************************/

.wrap-mini-post-box{
    display: flex;
    flex-wrap: wrap;
}

.mini-list-post-box {
    width: calc(50% - 10px);
    margin: 5px;
}

.mini-list-post-box .post_thumbnail {
    width: 150px;
    height: 100%;
}

.mini-list-post-box .post-box-contents.clearfix {
    display: flex;
    flex-wrap: wrap;
    height: 120px;
  }

.mini-list-post-box .post-meta-all {
    padding: 10px;
    flex: 1;
    position: relative;
}

.mini-list-post-box .post-title{
    font-weight: bold;
    font-size: .9em;
    padding-bottom: 5px;
    max-height: calc(4.5em);
    line-height: 1.5em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

.mini-list-post-box .post-date {
    font-size: 12px;
    position: absolute;
    bottom: 13px;
    left: 15px;
}

.mini-list-post-box .post-cat{
    background: #999;
    padding: 2px 0;
    font-size: 0.6em;
    border-radius: 2px;
    display: inline-block;
    vertical-align: middle;
}

@media screen and (max-width:768px){
  .mini-list-post-box {
    width: 100%;
    margin: 2px 0;
  }
}

@media screen and (max-width:599px){
  .mini-list-post-box .post-title{
    font-size: .8em;
  }

  .mini-list-post-box .post_thumbnail {
    width: 35%;
  }
}

/****************************************

          content

*****************************************/

.content .padding0 img{
  margin:0;
}

.post-upon {
    padding: 20px;
    padding-top: 0;
    background: #fff;
}

#single-main .post-author.author{
    position: relative;
    float: right;
    display: block;
    margin-bottom: 12px;
    margin-top:-8px;
}

#single-main li.post-author-name{
  max-width: none;
}

.single_thumbnail{
    text-align: center;
    background-position: center !important;
    background-size: 100% auto !important;
    position: relative;
    z-index: 0;
    overflow: hidden;
    width: 100%;
    img{
        max-width:100%;
        height:auto;
        vertical-align: middle;
        object-fit: contain;
        font-family: 'object-fit:contain;';
    }
    figcaption {
        background-color: inherit;
        text-align: right;
        padding: .2em 1em;
        font-size: .8em;
    }
    &.type-youtube{
        padding-top: 56.25%;
        height:0;
        iframe {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }
}




h1.single-post-title {
    margin:10px 0;
}

.post_footer_author_title {
    background: #eee;
    color: #333;
    border: 2px solid #eee;
    padding: 10px 20px;
    margin-top:25px;
}

.post_footer_author {
    min-height: 100px;
    border: 2px solid #eee;
    padding: 15px;
    margin-bottom: 10px;
}

.sc_post_author_user{
  padding: 20px 20px 10px 20px;
  background-color: #f6f6f6;
  border-radius: 5px;
  margin-bottom: 20px
}

.post-author-more{
    text-align: center;
    width: 100%;
    a{
        background-color: #000;
        padding: 8px;
        color: #fff !important;
        width: 90%;
        display: block;
        border-radius: 3px;
        font-weight: bold;
        margin: 10px auto;
        font-size: .9em;
        text-decoration: none;
    }
}

.post_author_user{
    display: flex;
    flex-wrap: wrap;
    .post_thum {
        text-align: center;
    }
}


.post_author_user .profile_sns li a{
  width: 28px;
  line-height: 28px;
}
.post_author_user .profile_sns li a i{
  margin: 0
}

.post_author_user .post_thum {
    text-align: center;
}

.post_author_user .post_thum img{
  border-radius: 50%;
  border:1px solid #ccc;
  margin: 0 auto;
}

.post_author_user_meta {
    vertical-align: middle;
    padding-left: 20px;
    flex: 1;
}

.post_author_user .post-author{
  float: none;
  font-size: 20px;
  padding-bottom: 8px;
  font-weight: bold;
  line-height: 1.5;
}

.post_author_user .post-author a{
  text-decoration: none;
}


.post_author_user_meta .post-description {
    font-size: 14px;
    overflow: auto;
    min-height: 50px;
    a{
      text-decoration: underline;
      color: cadetblue;
    }
}

.post_footer_author_title_post {
    font-weight: bold;
    border-bottom: 2px dotted #eee;
    margin: 10px 0;
    margin-top: 15px;
    clear: both;
}

.post_author_user .profile_sns{
  padding: 5px 0;
  white-space: nowrap;
}

#sidebar .post_author_user{
  display: block;
}

#sidebar .post_author_user .post-author{
  text-align: center;
}

#sidebar .post_author_user_meta{
  padding: 0;
}

#sidebar .post_author_user .profile_sns li a{
  width: 35px;
  line-height: 35px;
  padding: 0;
  margin: 0 3px;
}

@media screen and (max-width: 768px){
  .post_footer_author_title_post{
      padding: 0 10px;
  }
  .post_author_user .post_thum{
    width: 100%;
    text-align: center;
    margin-top:10px; 

  }

  .post_author_user{
    display: block;
  }
  .post_author_user_meta{
    padding: 0;
  }
  .post_author_user .post-author{
    text-align: center;
  }
  .post_author_user_meta .post-description{
    font-size: .8em;
    width: 100%;
    padding: 0 10px;
  }

}

.author-post-wrap{
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4,1fr);
  .author-post {
    flex:1;
    display: inline-block;
    vertical-align: top;
    a{
      text-decoration: none;
    }
  }
  @include mq-down(md){
    grid-template-columns: repeat(2,1fr);
  }
}

.author-post-meta{
    padding: 5px;
}

.post_footer_author .author-post-thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit:cover;';
}

.post_footer_author .author-post-cat span{
    background: #333;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 10px;
    color: #fff;
    padding: 1px 5px;
}

.post_footer_author .author-post-title {
    font-size: .9em;
    font-weight: bold;
}

.post_footer_author .author-post-date {
    font-size: 12px;
    color: #666;
}

.hentry .single-post-category{
    display: inline-block;
    margin-right: 5px;
    padding: 0.3em 0.5em;
    border-radius: 2px;
    margin-top: 5px;
    vertical-align: middle;
    background: #333;
    font-size: .8em;
}

.post .single-post-category a {
    color: #fff;
    vertical-align: middle;
}

#single-main .tag .tag-wrap {
    margin-left: 120px;
}


#single-main .post-meta .tag,#single-main .article_footer .tag {
    display: inline-block;
    margin-right: 5px;
    padding: .3em .5em;
    border-radius: 2px;
    margin-top: 5px; 
    vertical-align: middle;
    background: #efefef; 
    font-size: .8em;

}

#single-main .post-meta .tag:before,#single-main .article_footer .tag:before{
    content: "\f02b";
    margin-right: 5px;
    color: #7e9da7;
    font-family: fontAwesome;
}

#single-main .tag_area{
  padding: 0 5px;
}

.single_title,#comment-area .comment_title{
    background: 0;
    padding: 10px;
    border-radius: 0;
  font-size: 18px;
    border-bottom: 1px solid #ccc;
    margin-top: 15px;
}

.single_title a {
    color: #577fbc;
}


/****************************************

          page - 固定ページ

*****************************************/

#page-main{
    margin-bottom: 30px;
}

.single-post-main {
    margin-bottom: 10px;
}

.page_title{
    padding: 20px 30px;
    font-weight: normal;
    border-bottom: 1px solid #eee;
    margin-bottom: 0;
    background: #fff;
}

#page-main .widget,#main .widget,.maintop-widget,.main-bottom{
  margin-bottom: 10px;
  padding: 0;
  margin-top: 15px;
}

#page-main .widget > ul:first-of-type, #page-main .widget ul:first-child,#main .widget > ul:first-of-type, #page-main .widget ul:first-child{
  margin: 0;
}

#page-main .widget .wrap-post-title,#main .widget .wrap-post-title,.maintop-widget .wrap-post-title,.main-bottom .wrap-post-title{
  margin-bottom: 0;
  margin-top:0;
  top:-3px;
}

#page-main  .post_list .meta .post_list_title,#main  .post_list .meta .post_list_title{
    font-size: .9em;
    padding: 0;
    padding-top: 5px;
}

#page-main   .post_list .meta .desc,#main  .post_list .meta .desc {
    font-size: .7em;
}

/****************************************

          wp block

*****************************************/

.content > [class^="wp-block"]:not(.wp-block-dvaux-section) {
    z-index: 2;
    position: relative;
  }

/****************************************

          Comments Area

*****************************************/

.required{ color: #6495ED;}

.commets-list,
.trackback-list{
  overflow: hidden;
  margin-bottom: 40px;
  background: #f9f9f9;
}
.commets-list li,
.trackback-list li{
  overflow: hidden;
  margin-top: -1px;
  padding: .5em 1.5em;
  width: 100%;
  border-bottom: 1px solid #ccc;
}
.commets-list .avatar{
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

.comment-meta,
.reply,
.comment-notes,
.form-allowed-tags,
span.small{
  font-size: 80%;
}

.comment-body,.comment-body a{
  color: #000;
}
.comment-body p{
  clear: both;
}

p.form-submit {
    text-align: right;
}

.commets-list .children li.comment{
  background: #fff;
  padding: 0;
  border-bottom: none;
}

.comment-body {
    padding: 1em 0;
}


.commets-list .children li.comment .comment-body{
  padding: 1em;
  border-top:1px solid #eee;
}

div#comment-area .children .children {
    margin: 0;
    border-left: 1em solid rgba(18, 87, 115, 0.1);
    border-top: 2px solid #eee;
}

div#comment-area .children .children .children{
  margin-left: 1px;
  border-top: 0;
}

div#comment-area .single_title{
  margin: 0;
  border-top:1px solid #eee;
  background: #fff;
  color: #000;
}

div#comment-area .reply {
  text-align: right;
}

div#comment-area .reply a{
    background: #e2e2e2;
    padding: .5em 1em;
    border-radius: 3px;
}

div#comment-area #respond {
    padding: 15px 20px;
    border: 1px solid #eee;
}

.respondform_title{
  display: none;
}

#comment-area input[type="text"], #comment-area textarea {
  display: block;
  width: 100%;
  margin: 5px 0;
}


/****************************************

          recommend post

*****************************************/

.recommend-post{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  padding: 10px;
  gap: 10px;
  .single-recommend {
    width: 100%;
    position: relative;
    overflow: hidden;
    letter-spacing: normal;
    vertical-align: top;
    a{
      display: flex;
      gap: 10px;
      width: 100%;
      text-decoration: none;
    }
    .recommend-thumb {
      position: relative;
      background:#eee;
      width: 150px;
      font-size: .8em;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit:cover;';
        vertical-align: middle;
        background: #eee;
      }  
    }
    .recommend-meta {
      flex:1;
    }
    @include mq-down(md){
    
      a{
        flex-direction: column; 
      }
      .recommend-thumb {
        width: 100%;
      }

    }
  }

  
}

.recommend-post-title {
    font-weight: bold;
  }


.recommend-desc{
  font-size: .7em;
  color: #999;
  padding: 5px 0;
}

.recommend-title {
    font-size: .9em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: calc(3em + 16px);
    font-weight: bold;
}


.recommend-cat {
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    padding: 2px 4px;
    position: absolute;
    background: #333;
    bottom:0;
    right: 0;
}


/****************************************

          index.php Main

*****************************************/

.content-box{
  float: right;
  margin-top: -0.25em;
  width: 420px;
}
  .content-box .more-link{
    margin: 0;
  }

.thumbnail-box{
  float: left;
  width: 160px;
}
  .thumbnail-box img{
    width: 160px;
    height: 160px;
  }
  
/****************************************

          Category

*****************************************/

.category-header-wrap{
  color: #333;
    padding: 15px 0;
    border-bottom: 1px solid #eee;
}

.category-header {
    margin: auto;
    width: 570px;
}

.category-title{
  display:inline-block;
  vertical-align: middle;
  text-align: center;
}

.category-title-name{
  font-size: 35px;
}

.category-title-horline{
  margin: 0 0 10px 0;
  width:50px;
  height:5px;
  display:inline-block;
  vertical-align: middle;
  border-radius: 10px;
}

.category-title-line{
  margin:0 20px;
  width:5px;
  height:60px;
  background:#fff;
  display:inline-block;
  vertical-align: middle;
  border-radius: 10px;
}
.category-title-desc {
    width: 300px;
    overflow: auto;
  display:inline-block;
  vertical-align: middle;
  font-size: 15px;
}

.catpage_content_wrap {
    margin-bottom: 10px;
    margin-top:-10px;
    position: relative;
    background: transparent !important;
    overflow: hidden;
}

.catpage_content_wrap .cover{
  background-size: 100% auto;
  background-position: center center;
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left: 0;
  z-index: -1;
  filter: blur(2px) brightness(.8);
}

.catpage_description{
  font-size: 1.1em;
  margin-top: 10px;
}

.catpage_content_wrap .share{
  padding:0; 
}

.catpage_content_wrap  .innner {
    padding: 1em 0;
    width: 90%;
    margin: 0 auto;
}

.catpage_content_wrap .catpage_inner_content {
    width: 60%;
    float: left;
    padding: 1em;
    font-weight: bold;
    text-shadow: 1px 1px 0 #999;
}

.catpage_content_wrap .content {
    padding: 1em 40px;
    line-height: 1.5;
}

h1.catpage_title {
    margin: 0;
}

.catpage_tag {
    width: 40%;
    float: left;
    padding: 1em;
}

.catpage_tag a {
    background: #eee;
    color: #000;
    white-space: nowrap;
    border: 1px solid #ddd;
    padding: 5px 10px;
    margin: 3px 1px;
    display: inline-block;
    border-radius: 2px;
}

a.tag-cloud-link:before {
    content: "\f02b";
    margin-right: 5px;
    color: #7e9da7;
    font-family: fontAwesome;
}

.cat-post-main .content{
  margin: 0;
}

.cat-post-main{
  padding:1em 2em;
  margin-bottom: 10px; 
}

@media screen and (max-width:768px){
  .catpage_content_wrap .cover{
    background-size: auto 100%;
  }
  .catpage_content_wrap .catpage_inner_content {
    width: 100%;
  }

  .catpage_tag {
    display: none;
  }

  .catpage_content_wrap{
    margin-top: -5px;
  }

  .cat-post-main{
    padding: 1em;
  }
}

/****************************************

          author

*****************************************/

.author_title {
    background: #fff;
    padding: 2em;
    margin-bottom: 10px;
}

.author_title-thum {
    float: left;
    margin-right: 2em;
}

.author_title-thum img {
    border-radius: 50%;
}

.author_title-meta {
    float: left;
    margin-left: -200px;
    padding-left: 200px;
    width: 100%;
}

.author_title-name {
    font-size: 1.3em;
    font-weight: bold;
}

@media screen and (max-width:768px){
  .author_title-meta {
    float: left;
    margin: 0px;
    padding: 0px;
    width: 100%;
  }

  .author_title-thum {
    width: 100%;
    text-align: center;
  }

  .author_title-name{
    text-align: center;
    margin-bottom: 1em;
  }
}

/****************************************

          Sidebar

*****************************************/

#sidebar {
    width: 330px;
}

/* fit-sidebar */
.fit-sidebar-fixed{
  margin-top:0!important;
  margin-bottom:0!important;
  position:fixed;
}
.fit-sidebar-blank{
  z-index:0;
  background-color:transparent;
  visibility:hidden;
}


.widget{
    margin-bottom: 10px;
    padding: 10px;
    position: relative;
}

.singletop-widget .wrap-post-title {
    margin: -10px;
    margin-bottom: 20px;
}

.singletop-widget > div{
    margin: -10px;
    padding: 10px;
}


.widget > ul:first-of-type,
.widget ul:first-child,
.diver-tabwidget__content > ul:first-of-type{
    margin: -10px;
}

.widget > ul:first-of-type > li,
.widget ul:first-child > li,
.diver-tabwidget__content > ul:first-of-type > li{
    border-bottom: 1px solid rgba(50,50,50,.2);
}

.widget_text ul li i{
    vertical-align: middle;
    margin-right: 8px;
}

.textwidget{
  font-size: .9em;
}

.textwidget img {
    /* text-align: center; */
    display: block;
    margin: 0 auto;
}

#sidebar-recent-posts li,
#sidebar-popular-posts li{
  margin:20px 0;
}

.sidebar-thumbnail-box{
  float: left;
  width: 75px;
}

.sidebar-recent-posts-title{
  float: right;
  width: 165px;
}

.sidebar_content{
    margin-bottom: -20px;
    height: 100%;
}

.widgettitle{
    position: relative;
    font-size: 16px;
    padding: 8px 15px;
    margin: -10px;
    margin-bottom:10px; 
}

.tagcloud a{
    white-space: nowrap;
    border:1px solid #ddd;
    padding: 5px 10px;
    margin: 3px 1px;
    display: inline-block;
}

.tagcloud a:hover{
  background: #eee;
}

.widget_categories label,.widget_categories h2,
.widget_archive label,.widget_archive h2{
  display: none;
}

.cat-item .count,.widget li a .count {
    float: right;
    background: #eee;
    color: #666;
    padding: 1px .5em;
    display: inline;
    border-radius: 2px;
}

.screen-reader-text{
  display: none;
}

.widget_archive select,.widget_categories select{
    cursor: pointer;
    appearance: none;
    width: 100%;
    padding: 0.5em 1em;
    color: inherit;
    border: 1px solid #ccc;
    background: transparent;
    border-radius: 0;
    font-size: .8em;
}

.widget_archive select option, .widget_categories select option{
  color: #000;
}
 
#sidebar .widget select::-ms-expand {
    display: none;
}

.widget ul li.cpd-l{
  padding: 10px;
}

.maintop-widget,.mainbottom-widget{
    margin-bottom: 10px;
}

.single-top{
    margin:10px 0;
    padding: 0;
    border-radius: 3px;
}

.bottom_ad .widget{
  padding: 0;
}

.bottom_ad .widget ul{
  border:1px solid #eee;
}

.single-pcad{
    padding: 15px !important;
}

.in_loop {
    padding: 5px;
    margin-bottom: 5px;
}

.nav_inleft_wrap{
    float: right;
}

.nav_inleft,.header-logo #nav {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

header .searchsubmit{
    right: -1;
}

@media screen and (max-width: 959px){
    .nav_inleft_wrap{
        text-align: center;
        display: none;
    }
    .nav_inleft{
        display: none;
    }
}

/****************************************

          widget

*****************************************/
.widget.widget_text li a,.widget.widget_categories li a, .widget.widget_recent_entries li, .widget.widget_nav_menu li a, .widget.widget_pages li a, .widget.widget_archive li a, .widget.widget_mycategoryorder li a,.widget.widget_meta li a {
    display: block;
    padding: .8em 1em;
    margin: 0;
    position: relative;
    font-size: .9em;
}
.widget.widget_text li ul,.widget.widget_nav_menu li ul, .widget.widget_pages li ul, .widget.widget_categories li ul {
    padding: 0;
    margin: 0;
}

.widget.widget_text ul li,.widget.widget_categories ul li, .widget.widget_nav_menu li, .widget.widget_pages li, .widget.widget_archive li, .widget.widget_mycategoryorder li {
    margin: 0;
    padding: 0;
}

.widget.widget_nav_menu li ul li a:before, .widget.widget_pages li ul li a:before ,.widget.widget_categories li ul li a:before{
    content: '\f0da';
    color: #ccc;
    margin-right: .6em;
    font-family: fontAwesome;
}

.widget.widget_recent_entries li span.post-date{
  font-size: .8em;
}

.widget_nav_menu .menu_desc{
  display: none;
}

.diver_widget_adarea{
  margin-bottom: 10px;
  text-align: center;
}
.diver_widget_adlabel {
    font-size: .8em;
    text-align: center;
}

#main .diver_widget_adarea{
  margin-bottom: 10px;
}


#main .textwidget {
    padding: 10px;
}

.textwidget h2 {
    padding: 0.7em 1em;
    font-size: 1em;
    margin: 1em 0 1em;
}

.textwidget h3 {
    padding: .3em 1em;
    margin: 1em 0 .8em;
    font-size: 1em;
    border-bottom: 1px solid #555;
    color: #333;
    background:#fff; 
}

.textwidget h4 {
    position: relative;
    position: relative;
    font-weight: bold;
    font-size: 1em;
    padding: .2em 1em;
    color: #333;
    margin:.8em 0 .6em; 
}

.textwidget h4 {
    color: #333;
    font-size: 1em;
    font-weight: bold;
    margin:.6em 0 .8em;
}

.diver_widget_profile .coverimg{
    text-align: center;
    position: relative;
    padding: 0 1em;
}

.diver_widget_profile .coverimg  img{
  width:120px;
  height: 120px;
  border-radius: 50%;
  border:5px solid #fff;
  overflow: hidden;
  object-fit: cover;
  font-family: 'object-fit:cover;';
}


.diver_widget_profile .coverimg.on{
    background-size: 100% auto;
    height: 180px;
    margin-bottom: 60px;
}

@media screen and (max-width:959px){
  .diver_widget_profile .coverimg.on{
      background-size: 100%;
  }
}

.diver_widget_profile .coverimg.on img{
  position: relative;
  top: 120px;
}


.diver_widget_profile .coverimg.no img{
  border:5px solid #fff;
  width:160px;
  height: 160px;
}


.diver_widget_profile .img_meta{
  padding: .5em 0;
  text-align: center;
}

.diver_widget_profile .img_meta .name{
    font-weight: bold;
    line-height: 1.2;
}

.diver_widget_profile .profile_sns{
  padding-top: .6em;
}

.profile_sns li{
  display: inline-block;
}

.profile_sns li a{
  color: #fff !important;
  border-radius:50%;
  display: block;
  width: 35px;
  line-height: 35px;
  text-align: center;
  margin: 0 3px;
}


.profile_sns .facebook{
  background-color:#3b5998;
}

.profile_sns .twitter{
  background-color:#00acee;
}

.profile_sns .instagram{
  background-color:#D93177;
}

.profile_sns .youtube{
  background-color: #f00;
  }

.profile_sns .sns{
  background-color:#8BC34A;
}


.diver_widget_profile .meta {
    padding: .5em 1em;
    font-size: .9em;
}

.diver_widget_profile .button a {
    padding: .5em;
    background-color: #eee;
    border-radius: 2px;
    width: 80%;
    font-size: .8em;
}


.widget .single_title {
    margin: -10px;
    margin-bottom: 15px;
}

.single-related-area .textwidget,.single-recommend-area .textwidget{
  padding:1em; 
}

.widget-h2 {
    margin: 1em 0;
}

.widget-h2 .widgettitle{
  margin: 0;
}

.widget_rss ul li a {
    padding: 5px 10px;
    display: inline-block;
    font-size: .85em;
}

.container_top_widget,.container_bottom_widget{
  margin: 0px auto;
}
.container_top_widget {
    margin-top: 20px;
}
.containerwidget {
    margin: 10px auto;
}

.widget li.recentcomments{
    padding: 10px;
    font-size: .9em;
}

.recentcomments > a {
    font-weight: bold;
}

.widget ul.sub-menu .menu_title {
    display: inline-block;
}


/****************************************

          Search widget

*****************************************/

form.search-widget {
    padding: 20px;
}

#sidebar form.search-widget {
    padding: 5px 10px;
}

.search-widget__col {
    margin-bottom: 10px;
}

input.search-widget__input {
    width: 100%;
    padding: 10px 8px;
    border: 2px solid #eee;
}

label.search-widget__label {
    width: 100%;
    display: block;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 5px;
}

.search-widget__select {
  overflow: hidden;
  width: 100%;
  position: relative;
  border-radius: 2px;
  border: 2px solid #eee;
  background: #ffffff;
}

.search-widget__select::before {
  position: absolute;
  top: 0.8em;
  right: 0.8em;
  width: 0;
  height: 0;
  padding: 0;
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #999;
  pointer-events: none;
}
.search-widget__select select {
  width: 100%;
  padding-right: 1em;
  cursor: pointer;
  text-indent: 0.01px;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  background: transparent;
  background-image: none;
  box-shadow: none;
  appearance: none;
  padding: 8px 38px 8px 8px;
  color: #666; 
}
.search-widget__select select::-ms-expand {
    display: none;
}

.search-widget__checkbox { display:none; }
.search-widget__checkbox-label{
  font-size: 13px;  
  padding-left: 20px;
  margin-right: 20px;  
  position:relative;
  cursor: pointer;  
  display: inline-block;
}
.search-widget__checkbox-label:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  border: 2px solid #ccc;
}
.search-widget__checkbox:checked + .search-widget__checkbox-label{
  color: #3c82ff;
}
.search-widget__checkbox:checked + .search-widget__checkbox-label::after{
  content: "";
  display: block;
  position: absolute;
  top: -4px;
  left: 5px;
  width: 7px;
  height: 14px;
  transform: rotate(40deg);
  border-bottom: 3px solid #3c82ff;
  border-right: 3px solid #3c82ff;
}

button.search-widget__submit{
    margin: 0 auto;
    width: 100%;
    padding: 8px 13px 7px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: .1em;
    background: #3c82ff;
    color:#fff;
    border: none;
    margin-top: 5px;
}

/****************************************

          widget post_list

*****************************************/

.widget_post_list{
	width: 100%;
	position:relative;
	letter-spacing: normal;
	transition: .4s;
	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
		font-family: 'object-fit:cover;';
		vertical-align: middle;
		position: absolute;
		top:0;
		right: 0;
	}
	.post_list_thumb{
		float: left;
		width: 100px;
		height: 80px;
		position: relative;
		background-color:#eee;
		.post_list_cat{
			position: absolute;
			bottom: 0;
			right: 0;
			background:#333;
			color: #fff;
			font-size: 10px;
			padding: 2px 4px;
			border-radius: 3px 0 0 0;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			word-break: break-all;
			overflow: hidden;
		}
	}
	.meta{
		float: right;
		overflow: hidden;
		width: 100%;
		flex: 1;
	}
	a{
		padding: 8px;
		display: flow-root;
	}
  .newlabel{
    font-size: 10px;
    background: transparent;
    &:before{
      content: "";
      top: 0;
      left: 0;
      border-bottom: 4em solid transparent;
      border-left: 4em solid #f66;
      position: absolute;
    }
    span{
      position: absolute;
      transform: rotate(-45deg) scale(0.9);
      transform-origin: 50%;
      left: 2px;
      top: 6px;
    }
  }
	&.grid a{
		// flex-direction: column;
	}
}

 .widget_post_list .post_list_tag .post_list_cat{
  line-height: 1;
  position: relative;
  display: inline-block;
  background:#333;
  color: #fff;
  font-size: 10px;
  padding: 3px 4px;
  border-radius: 2px;
}


 .widget_post_list .meta .post_list_tag{
  line-height: 0;
}
 .widget_post_list .meta .post_list_tag .tag{
  font-size: .5em;
  border: 1px solid #ccc;
  color:#666;
  padding: 2px 3px;
  border-radius: 2px;
  margin: 2px;
  display: inline-block;
  line-height: 1;
}

 .widget_post_list .meta .post_list_title{
    font-size: .9em;
  }

  #sidebar .widget_post_list .meta .post_list_title{
    font-size: 13px;
  }


 .widget_post_list .meta .post_list_date{
  font-size: .6em;
}

#sidebar  .desc{
  display: none;
}

#sidebar  .widget_post_list.grid {
    width: 50%;
    border-bottom: 0;
    display: inline-block;
    vertical-align: top;
}

 .widget_post_list.grid .post_list_thumb{
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 61.8%;
    position: relative;
}

.content .widget_post_list.grid .meta .post_list_title,
.single-post-main .widget_post_list.grid .meta .post_list_title,
#main .widget_post_list.grid .meta .post_list_title,
#page-main .widget_post_list.grid .meta .post_list_title{
  font-size:1em;
}

#main .widget_post_list.grid .meta .post_list_title{
  padding: 5px 0;
}

.content .widget_post_list.grid .meta .desc,
.single-post-main .widget_post_list.grid .meta .desc,
#main .widget_post_list.grid .meta .desc,
#main .widget_post_list .meta .desc,
#page-main .widget_post_list.grid .meta .desc{
  font-size:.7em;
}


/* .post_list.grid:nth-child(odd) {
    border-right: 1px solid #ccc;
}
*/
#sidebar  .widget_post_list.grid.first:first-child {
    width: 100%;
}

#sidebar  .widget_post_list.grid.first:first-child .post_list_thumb{
      height: 130px;
}

 .widget_post_list.grid.first:first-child .meta .post_list_title {
  font-size: 1em;
}

.content .widget_diver_widget_newpost ul {
  padding: 0;
}

.content .widget_post_list img{
  margin: 0;
}

.content .widget_post_list .meta .post_list_title {
    font-size: 1em;
    line-height: 1.5;
    margin-bottom: 5px;
    font-weight: bold;
}

.content .widget_post_list .meta .desc {
    font-size: .8em;
}

.content .widget_diver_widget_newpost_grid ul{
  padding: 0;
}

ul.diver_widget_post_grid{
  display: flex;
  flex-wrap: wrap;
  li {
    width: 33.3333%;
  }
}

.content .widget_post_list.grid,
.single-post-main .widget_post_list.grid,
#main .widget_post_list.grid,
#page-main .widget_post_list.grid{
  width: 33%;
  vertical-align: top;
  margin: .15%;
  border-bottom: none;
  background: #fff;
}



.content .widget_post_list.grid .post_list_thumb{
  height: 120px;
}

#main .widget_post_list{
  background: #fff;
}
@media screen and (min-width: 769px) and (max-width: 959px){
  #sidebar  .widget_post_list.grid.first:first-child {
    height: 220px;
  }

  #sidebar  .widget_post_list.grid.first:first-child .meta {
    margin-left: -350px;
    padding-left: 350px;
  }

  #sidebar  .widget_post_list.grid.first:first-child .post_list_thumb {
      height: 200px !important;
      width: 340px;
      float: left;
      padding: 0;
  }

  #sidebar  .widget_post_list.grid {
      width: 33.3%;
  }

   .widget_post_list.grid.first:first-child .meta .post_list_title {
    font-size: 1.3em;
    height: 160px;
  }
}

.single-post-main .widget_post_list.grid .post_list_thumb,#main .widget_post_list.grid .post_list_thumb {
    width: 100%;
    height: 150px;
}

.single-post-main .widget_post_list.grid .meta .post_list_title,#main  .widget_post_list.grid .meta .post_list_title,#main  .widget_post_list .meta .post_list_title{
  font-weight: bold;
}

#main .widget_diver_widget_newpost_grid .wrap-post-title,#main .widget_diver_widget_newpost .wrap-post-title{
    margin-bottom: 8px;
}

#main .widget .widget_post_more a {
    display: block;
    padding: 10px 20px;
}


@media screen and (max-width:768px){
   #main  .widget_post_list .meta .desc{
      display: none;
   }
   #main  .widget_post_list.grid .post_list_thumb {
      height: 80px;
    }

    .content .widget_post_list.grid,
    .single-post-main .widget_post_list.grid,
    #main .widget_post_list.grid,
    #page-main .widget_post_list.grid{
      width: 49.7%;
    }
}

@media screen and (max-width:599px){
    #main li.post_list.grid {
      width: 49.7%;
    }
}


.widget .widget_post_more{
  text-align: center;
  width: 100%;
  border-top: 1px solid #ccc;
}

a.diver_widget_morelink::after {
    font-family: fontAwesome;
    content: "\f105";
    margin-left: 10px;
    font-weight: bold;
}

/****************************************

          appeal box

*****************************************/
.appeal_title {
    padding: 10px;
    background: #333;
    color: #fff;
}
.appeal_box{
    background: #333;
    color: #fff;
}

.appeal_img{
    text-align: center;
    max-width: 100%;
}

.appeal_meta .button a{
    background: #f44336;
}

.appeal_desc {
    font-size: .9em;
    padding: 1em .3em;
    line-height: 1.8;
}

/****************************************

          share plz

*****************************************/

#share_plz {
    z-index: 10;
    text-align: center;
    padding:10px 5px;
    position: sticky;
    top: 85px;
    .p-entry__pushButton{
        width: auto;
    }
    .p-entry__pushLike{
        padding:0;
        height: 78px;
    }
    .p-entry__push{
        margin:0;
        background: #fff; 
    }
    .share_sns{
        padding:5px 10px;
        width: 100%;
    }
}


/****************************************

          404 page

*****************************************/

.notfofund_title {
    text-align: center;
    font-size: 50px;
    padding: 50px 0;
    color: #666;
}

.notfofund_text {
    padding: 20px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
}

.notfofund_slick_title{
    text-align: center;
    font-size: 21px;
    margin: 15px;
}

.error404 .bx-viewport{
    margin-bottom: 20px;
}

@media screen and (max-width:768px){

  .notfofund_title {
      font-size: 20px;
      padding: 20px 0;
  }

}

/****************************************

          Big footer

*****************************************/
#bigfooter{
    background:#eee;
}

.footer_title {
    padding: 5px;
    margin-bottom: 5px;
}

#bigfooter li{
    padding: 5px;
}

#bigfooter li:before{
  font-family: fontAwesome;
    content:"\f138";
    margin-right: 8px;
    font-size: 0.9em;
}

#bigfooter ul ul li {
    padding-right: 0;
}

#bigfooter ul ul.children {
    padding-top: 5px;
}

#bigfooter a {
    font-size: 13px;
}

.bigfooter_wrap {
    width: 90%;
    margin: 0 auto;
    text-align: center;
}

.bigfooter_colomn {
    display:inline-block;
    vertical-align: top;
    padding:25px 10px !important;
    text-align:left;
}

.bigfooter_colomn .menu_desc {
    display: none;
}

.bigfooter_col {
    padding: 5px;
}

.bigfooter_wrap_main {
    background: #eee;
    padding: 20px 0;
    margin-top: 20px;
}

#bigfooter  ul li:before {
    content: none;
}

@media screen and (max-width:768px){
    .bigfooter_colomn,.bigfooter_wrap{
        width: 100% !important;
    }

    .bigfooter_colomn.col3,.bigfooter_colomn.col4{
        padding: 5px !important;
    }

    .bigfooter_center{
        margin:0;
    }
}

/****************************************

          Footer

*****************************************/

#footer {
    font-size: 13px;
    clear: both;
    background: #999;
    margin-top: auto;
    margin-bottom: 0;
}

#footer .menu_desc{
  display: none;
}

.footer_navi {
    display: inline-block;
}

.footer_content {
    width: 90%;
    margin: 0 auto;
    padding:15px 0;
}

#copyright{
    float: right;
}

.footer_navi ul li {
    font-size: 13px;
    display: inline-block;
    padding: 0 5px;
}

@media screen and (max-width:768px){
    .footer_navi{
        width: 100%;
        text-align: center;
    }

    #copyright{
        float: none;
        text-align: center;
        padding: 10px 0;
    }
}

/****************************************

          footer menu
          
*****************************************/


#footer_sticky_menu {
    position: fixed;
    bottom: -100px;
    width: 100%;
    z-index: 1000;
    table-layout: fixed;
    border:none;
    box-shadow: 0 0 5px rgba(0,0,0,.2);
}

.footermenu_col{
    position: relative;
    text-align: center;
    background: #fff;
    color: #555;
    border:none;
    border-right: 1px solid rgba(100,100,100,.6);
    padding:0; 
    height: 60px;
    vertical-align: middle;
}

.footermenu_col:last-child {
    border-right: none;
}

#footer_sticky_menu a,#footer_sticky_menu button {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 !important
}

#footer_sticky_menu a i {
    font-size: 1.4em;
}

#footer_sticky_menu .footermenu_title {
    font-size: .6em;
}


.lity-container{
  width: 100%;
}

.footermenu_sns li{
  flex: 1;
}

.footermenu_sns li a{
  font-size: .6em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footermenu_sns a.line {
    padding: 1em 0;
}

.footermenu_sns .sns_name{
  display: none;
  font-weight: bold;
}

/****************************************

          footer cta
          
*****************************************/

#footer_cta {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: .5em 1.5em;
    z-index: 999;
    display: none;
    max-height: 120px;
}

#footer_cta figure{
    height: 100%;
    width: 55%;
    float: right;
    text-align: center;
}

#footer_cta figure img {
    max-height: 100%;
    object-fit: contain;
    font-family: 'object-fit:contain;';
    vertical-align: middle;
}

#footer_cta .footer_cta_wrap{
  position: relative;
  float: left;
  width: 95%;
  height: 90px;

}

#footer_cta .footer_cta_meta{
  float: left;
  padding: 8px;
  width: 45%;
}

#footer_cta .title {
    font-weight: bold;
    font-size: 26px;
}

#footer_cta  .desc {
    font-size: 16px;
    margin-top: 5px;
}

#footer_cta a.close {
    color: #666;
    font-size: 50px;
    float: left;
    width: 50px;
    line-height: 88px;
    opacity: .3;
}

a.fcta_open {
    font-size: 20px;
    color: #fff;
    bottom: -50px;
    display: none;
    left: 30px;
    position: fixed;
    line-height: 40px;
    text-align: center;
    padding: 0px 15px;
    border-radius: 5px;
}

@media screen and (max-width:1201px){
  #footer_cta {
    padding:.3em; 
    line-height: 0;
  }


  #footer_cta figure {
    height: 100%;
    width: 100%;
    float: right;
  }

  #footer_cta .footer_cta_meta{
    display: none;
  }

  #footer_cta .footer_cta_wrap{
    float: right;
  }

  #footer_cta a.close {
    font-size: 30px;
    width: 30px;
    line-height: 88px;
    position: absolute;
}
}

@media screen and (max-width:768px){

  #footer_cta a.close {
      font-size: 20px;
      width: 20px;
      line-height: 20px;
      top: -20px;
      left: 0;
      background: #333;
  }
}
/****************************************

          metabox
          
*****************************************/

  #title-counter {
    text-align: right;
    background:#fff;
    width:100px;
    padding:5px;
    margin:5px 0;
    margin-left: auto;
    border:1px solid #ccc;
  }
  .title-counter-length-over {
    color: #f00;
    font-weight: bold;
  }

/****************************************

          Highlights
          
*****************************************/

.hljs{
    margin:10px 0;
    padding: 20px;
}


/****************************************

          sns
          
*****************************************/

header .share{
    padding:10px 20px;
}
.share{
  width:100%;
  padding:10px 0;
}
.share.short{
  padding:1.2em;
}

.sns{
  margin:0 auto;
  width:100%;
  text-align: center;
}
.sns ul {
  display: flex;
  list-style:none;
  width:100%;
  letter-spacing: -0.4em;
  word-spacing: 0.1em;
}
.sns li {
  display: inline-block;
  width: 20%;
  vertical-align: middle;
  padding:3px; 
  letter-spacing: 0.1em;
}

.sns.c1 li {
  width: 100%;
}
.sns.c2 li {
  width: 50%;
}
.sns.c3 li,.sns.c6 li {
  width: 33.3333%;
}
.sns.c4 li,.sns.c7 li {
  width: 25%;
}
.sns.c5 li {
  width: 20%;
}

.sns.mini li{
  width: auto;
}

.sns a {
  font-size:.7em;
  font-weight: bold;
  line-height: 1.8;
  position:relative;
  display:block;
  padding:.6em 0;
  color:#fff;
  text-align:center;
  text-decoration: none;
  border-radius: 2px;
}

.sns a:before,
.sns a i{
  margin-right:0.2em;
  margin-left:0.2em;
  vertical-align: middle;
  font-size: 1.4em;
}

.sns a .count{
  font-size:10px;
  margin-left: 2px;
}

/* ツイッター */
.sns a.twitter {
  background:#00acee;
    border:2px solid #00acee;

}
.sns a.twitter:hover {
  background:#fff;
  border:2px solid #0092ca;
  color:#0092ca;
}

/* Facebook */
.sns a.facebook{
  background:#3b5998;
  border:2px solid #3b5998;
}
.sns a.facebook:hover {
    background:#fff;
    border:2px solid #2c4373;
    color:#2c4373;
}

/* はてぶ */
.sns  a.hatebu{
  background:#5d8ac1;
  border:2px solid #5d8ac1;
}
.sns a.hatebu:hover {
    background:#fff;
    border:2px solid #5d8ac1;
    color:#5d8ac1;
}
.sns  a.hatebu:before{
  content:'B!';
  font-weight:bold;
  line-height: 1;
} 

/* LINE */
.sns  a.line{
  background:#25af00;
    border:2px solid #25af00;

}
.sns a.line:hover {
    background:#fff;
    border:2px solid #25af00;
    color:#25af00;
}

/* Pocket */
.sns  a.pocket{
  background:#f03e51;
      border:2px solid #f03e51;

}
.sns  a.pocket:hover {
    background:#fff;
    border:2px solid #f03e51;
    color:#f03e51;
}

/* RSS */
.sns  a.rss{
    background:#ffb53c;
    border:2px solid #ffb53c;

}
.sns  a.rss:hover {
    background:#fff;
    border:2px solid #ffb53c;
    color:#ffb53c;
}

/* Feedly */
.sns  a.feedly{
  background:#87c040;
  border:2px solid #87c040;
}
.sns  a.feedly:hover {
    background:#fff;
    border:2px solid #87c040;
    color:#87c040;
}

span.sns_name {
    margin-left: 5px;
    vertical-align: middle;
}

.sns span.sns_count{
    font-weight: bold;
    display: inline-block;
    line-height: 1.5em;
    padding: 0 3px;
    margin: 0 .2em;
    border-radius: 3px;
}

#share_plz div.sns_count {
    background: #fff;
    border-radius: 2px;
    color: #333;
}

.sns.small a {
    display: inline-block;
    padding: 1.5px 5px;
    color: #fff;
    font-size: .7em;
    vertical-align: top;
    margin-bottom: 5px;
}

.sns.small {
    text-align: center;
}


/*@media screen and (max-width:768px){
    .sns li {
      width: 16.6666%;
    }
}*/

@media screen and (max-width:599px){
    span.sns_name {
        display: none;
    }

    .sns a:before,
    .sns a i{
      font-size: 1.8em;
    }
}

/****************************************

          pagenation
          
*****************************************/

.pagination{
    text-align: center;
    margin-bottom:10px;
    letter-spacing: -.4em;
}

a.page-numbers:hover {
    background: #eee;
}

a.page-numbers,
.pagination .current{
    color: #607d8b;
    padding: 12px 22px;
    text-decoration: none;
    vertical-align: middle;
    display: inline-block;
    letter-spacing: 0;
    margin: 0 3px;
    border-radius: 3px;
}
.pagination .current{
    background: #607d8b;
    color: #fff;
    font-weight: bold;
}

span.page-numbers.dots {
    letter-spacing: 0;
    padding: 8px .5em;
    vertical-align: middle;
}

.pagination a{
    letter-spacing: normal;
    background: #fff;
}

a.prev.page-numbers {
    margin-right: 3px;
}

a.prev.page-numbers:before{
  font-family: fontAwesome;
  content:"\f100";
}

a.next.page-numbers{
    margin-left: 3px;
}

a.next.page-numbers:before{
  font-family: fontAwesome;
  content:"\f101";
}

a.prev1.page-numbers {
    margin-right: 3px;
}

a.prev1.page-numbers:before{
  font-family: fontAwesome;
  content:"\f104";
}

a.next1.page-numbers{
    margin-left: 3px;
}

a.next1.page-numbers:before{
  font-family: fontAwesome;
  content:"\f105";
}

@media screen and (max-width:959px){
    .pagination{
        margin:15px 0;
        display: flex;
        justify-content: center;
    }

    a.page-numbers{
      padding: 8px 0;
      width: 15%;
    }

   .pagination .current{
      padding: 8px 0;
      width: 30%;
   }

}

/****************************************

        responsive mmenu / drawer menu
          
*****************************************/

@media screen and (min-width: 959px){
  .header_search,.header-logo .drawer-nav-btn,.drawer-nav-btn-wrap{
    display: none !important;
  }

}

.header_search{
    position: absolute !important;
    top: 0;
    right: 0;
    width: 55px;
    height: 100%;
}


a.header_search_btn{
    display: block;
    width: 100%;
    height:100%;
    font-size: 22px;
    line-height: 0;
    color:#fff;
}

a.header_search_btn .header_search_inner{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
}

.header_search_title{
  text-align: center;
  font-size: .3em;
  padding-top: .3em;
  line-height: 1;
  display: none;
}

#header_search{
  background-color: #fff;
  width: 85vw;
  height: 80vh;
  overflow: auto;
  margin: 0 auto;
  border-radius: 5px;
  font-size: .9em;
}



.drawer-overlay{
  transition: .4s;
}

.drawer-overlay.active {
  cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    z-index: 9999;
}

.drawer-nav-btn-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 55px;
    height: 100%;
}

.drawer-nav{
    background: #fff;
    margin: 0;
    padding: 0;
    list-style: none;
    top: 0;
    z-index: 99999;
    -webkit-overflow-scrolling: touch;
    width: 80%;
    max-width: 300px;
}


.drawer-nav{ 
    height: 100%;
    transition: .35s ease-in-out;
    overflow-y: scroll;
    position: fixed;
    left : -100%;
}

.drawer-nav.active{
    left : 0px;
}

.drawer-nav-btn {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.footermenu_col .drawer-nav-btn {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top:0;
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
}

.drawer-nav-btn span {
    height: 3px;
    background-color: #000;
    top: calc(50% - 1.5px);
}

.drawer-nav-btn:before {
    content: "";
    top: calc(25% - 3px);
    border-top: 3px solid #000;
}

.drawer-nav-btn:after {
    content: "";
    bottom: calc(25% - 3px);
    border-bottom: 3px solid #000;
}

.drawer-nav-btn:before, .drawer-nav-btn:after, .drawer-nav-btn span {
    display: block;
    width: 80%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    transition: .35s ease-in-out;
    border-radius: 5px;
}

.drawer_content_title {
    padding: 10px;
    margin-bottom: 10px;
    background: #eee;
}

.drawer-nav .diver_widget_adarea{
  padding: 10px;
}


.drawer-nav .widget > ul:first-of-type, .drawer-nav .widget ul:first-child{
  margin:0;
  margin-top:-10px; 
}

.footermenu_col .drawer-nav-btn span,.footermenu_col .drawer-nav-btn:before,.footermenu_col .drawer-nav-btn:after{
  background:none;
  border:none;
}

/****************************************
          search box
*****************************************/
.lity_content{
    padding: 1em;
    margin: 1em;
    background: #fff;
    border-radius: 5px;
  }

.searchbox_content_title {
    padding: 10px;
    margin-bottom: 10px;
    background: #eee;
}

#header_search .diver_widget_adarea{
  padding: 10px;
}

#header_search .widget > ul:first-of-type, #header_search .widget ul:first-child{
  margin:0;
  margin-top:-10px; 
}

/********************************************************************************

          responsive
          
*********************************************************************************/

#main-wrap.fullpage,
#main-wrap.fullpage #page-main,
#main-wrap.fullpage .single-post-main {
    width: 100%;
    margin: 0 auto;
    padding: 0;
}
#main-wrap.fullpage .content{
  margin-bottom: 0;
}

@media screen and (max-width:1200px){
    #main-wrap{
    width:96%;
  }
  
  img {
      max-width: 100%;
  }
  
  .single-post-main,.catpage_content_wrap .content{
      padding: 15px;
  }

}
@media screen and (max-width:959px){
    #main{
      margin: 0 !important;
      padding: 0 .5em !important;
    }

    #single-main, #page-main{
      margin: 0 !important;
      padding: 0 !important;
    }

    #sidebar{
        width: 100%;
        padding: .5em;
    }

}


@media screen and (max-width:768px){

    #main-wrap{
        width:100% !important;
        margin:5px auto; 
    }

  #main,#single-main,#page-main{
    width:100% !important;
  }

  #single-main,#page-main{
    padding: 0;
  }

  .hentry{
    padding:0 !important ;
    margin:0 !important;
  }

    .menu_desc{
        display: none;
    }

    .sidebar_content {
        padding: 10px 0;
    }

    h1{
      font-size: 1.3em;
      margin: 5px 0;
    }

    h1.single-post-title{
      font-size: 1.2em;
      line-height: 1.3;
    }

    h2{
      font-size: 1.3em;
    }

    h3{
      font-size: 1.2em;
    }

    h4{
      font-size: 1.1em;
    }

    .page_title{
      padding: 15px 20px;
    }

    .single-post-date,.post-meta-bottom span {
        font-size: .7em;
        margin-right: 5px;
    }

    .post .post-meta{
        padding: 10px;
    }

    .post .single-post-category{
        font-size: .7em;
        padding: 3px 6px;
        margin-right: 3px;
    }

    #single-main .post-meta .tag{
        padding: 3px 6px;
        font-size: .7em;
    }

    #single-main .post-sub {
        padding: 0px;
    }

    .post_footer_author{
        padding: 10px;
    }

    .article_footer{
      padding: 0;
      margin-top:-5px;
    }

    .single-post-main{
      padding: 10px;
      overflow: hidden;
    }

}

/****************************************

          shortcode

*****************************************/

.col2,.sc_col2,.col3,.sc_col3,.col3_2,.sc_col3_2,.col4,.sc_col4,.col4_3,.sc_col4_3{
    float: left;
    padding: 10px;
    & > *:first-child{
      margin-top: 0;
    }
    & > *:last-child{
      margin-bottom: 0;
    }
}

.col2 img,.col3 img,.col3_2 img,.col4 img,.col4_3 img{
  max-width:100%;
}

.col2,.sc_col2 {width: 50%;}
.col3,.sc_col3 {width: 33.333333%;}
.col3_2,.sc_col3_2 {width: 66.666666%;}
.col4,.sc_col4 {width: 25%;}
.col4_3,.sc_col4_3 {width: 75%;}

@media screen and (max-width: 768px){
.col2,.col3,.col4,.col3_2,.col4_3,
.sc_col2.sp,.sc_col3.sp,.sc_col4.sp,.sc_col3_2.sp,.sc_col4_3.sp
 {width: 100%;float: none;}

.col2_sp{width: 50%;float: left;}
.col3_sp{width: 33.3%;float: left;}

}


.aside-normal {
    background: #fff48f;
    color: #333;
    border-radius: 5px;
    padding: .8em 1em;
    margin-bottom: 1.6em;
}

.aside-warning {
    background: rgba(255,0,0,0.2);
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 1em 1.5em;
    margin-bottom: 1.6em;
}

.sc_sns i {
    color: #fff;
}

.sc_sns a {
    display: block;
    position: relative;
    text-align: center;
    padding: 0.8em;
    margin: 5px 0;
    border-radius: 3px;
    color: #fff;
}

.sc_sns.solid a{
    top: -3px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .4);
    transition: none;
} 

.sc_sns.solid a:hover{top:0;}
.sc_facebook a {background: #315096;}
.sc_facebook.solid a {box-shadow: 0 7px 0 #2c4373;}
.sc_facebook.solid a:hover {box-shadow: 0 3px 0 #2c4373;}
.sc_facebook.color a:hover {background: #2c4373;}
.sc_twitter a {background: #55acee;}
.sc_twitter.solid a {box-shadow: 0 7px 0 #0092ca;}
.sc_twitter.solid a:hover {box-shadow: 0 3px 0 #0092ca;}
.sc_twitter.color a:hover {background:#0092ca;}
.sc_instagram a{background: #3f729b;}
.sc_instagram.solid a{box-shadow: 0 7px 0 #43638b;}
.sc_instagram.solid a:hover {box-shadow: 0 3px 0 #43638b;}
.sc_instagram.color a:hover {background:#43638b;}

.sc_googleplus a{background: #dd4b39;}
.sc_googleplus.solid a{box-shadow: 0 7px 0 #ad3a2d;}
.sc_googleplus.solid a:hover {box-shadow: 0 3px 0 #ad3a2d;}
.sc_googleplus.color a:hover {background:#ad3a2d;}

.sc_sns.simple a{background: #aaa;}
.sc_sns.simple a:hover{background: #888;}
.sc_sns.simple.solid a{box-shadow: 0 7px 0 #888;}
.sc_sns.simple.solid a:hover{box-shadow: 0 3px 0 #888;}
.sc_sns.circle a{border-radius: 50%;padding: 0.9em 0.8em;}

.sc_marker{background: linear-gradient(transparent 50%, #ffff66 50%);}
.sc_marker.red{background: linear-gradient(transparent 50%, #ffd9d9 50%);}
.sc_marker.blue{background: linear-gradient(transparent 50%, #cfdbff 50%);}

.sc_marker-animation.active{
    background-position: -100% .4em;
    transition: 2.5s;
}
 
.sc_marker-animation {
    background-image: linear-gradient(left, transparent 50%, rgb(255, 247, 2) 50%);
    background-repeat: repeat-x;
    background-size: 200% .8em;
    background-position: 0 .5em;
    font-weight: bold;
}

.sc_marker-animation.red {
    background-image: linear-gradient(left, transparent 50%, rgba(255, 0, 0, 0.3) 50%);
}
.sc_marker-animation.blue {
    background-image: linear-gradient(left, transparent 50%, rgba(100, 149, 237, 0.5) 50%);
}

.fontsize.\31{font-size: 0.8em}
.fontsize.\32{font-size: 0.9em}
.fontsize.\33{font-size: 1.1em}
.fontsize.\34{font-size: 1.3em}
.fontsize.\35{font-size: 1.4em}
.fontsize.\36{font-size: 1.5em}
.fontsize.\37{font-size: 1.6em}
.fontsize.\38{font-size: 1.7em}
.fontsize.\39{font-size: 2em}

.fontcolor{color:red;}
.fontbackground{background:#eee;padding: 2px 5px;}

.sc_getpost{
    box-shadow: 0 3px 10px #ddd;
    box-shadow: 0 2px 5px rgba(100, 100, 100, 0.25);
    position: relative;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    margin: 1em 0;
    border:1px solid #ddd;
    background: #fff;
    line-height: 1.5;
}

.sc_getpost:hover{background: #eee;}

.sc_getpost a, .content .sc_getpost a{
    text-decoration: none;
    color: #000;
    padding:10px;
    display: block;
    overflow: hidden;
}

.content .sc_getpost p {padding: 0;display: none;}

.sc_getpost_thumb{
    width: 150px;
    float: left;
    margin-right: 10px;
}

.sc_getpost_cat{
  background: rgba(0,0,0,0.7);
  color: #fff ;
  padding: 3px 6px;
  font-size: .6em;
  position: absolute;
  bottom: 0;
  right: 0;
}

.sc_getpost .title{
    font-weight: bold;
    font-size: .9em;
    line-height: 1.5;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.sc_getpost .substr {
    font-size: 0.8em;
    line-height: 1.5;
    color: #666;
    margin-bottom: 1.5em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.sc_getpost .date{
    font-size: 0.8em;
    position: absolute;
    right: 15px;
    bottom: 5px;
}

.sc_getpost .badge{
  font-size: .8em;
  padding: 0.4em 0.6em;
  display: inline-block;
  margin-right: 5px;
}

#sidebar .sc_getpost{
  box-shadow: none;
  margin: 0;
}

#sidebar .sc_getpost_thumb {
    width: 100%;
    height: 150px;
    margin-bottom: 10px;
}

#sidebar .sc_getpost img{
  width: 100%;
  height: 100%;
}

#sidebar .sc_getpost .sc_getpost .title{
  font-size: 14px;
}


.tab_area{
    overflow:hidden;
    display:table;
    margin:0;
    }
    
.tab_area div{
    /*Tabの装飾*/
    width:139px; 
    height:49px; 
    text-align:center; 
    border: 1px solid #ccc;
    cursor: pointer; 
    vertical-align:middle; 
    display:table-cell;  
    color:#333333;
    background:#eee;
    }
    
.sc_tabs_area { 
    padding:10px 0; 
    overflow: hidden;
}
    
.sc_tabs_area div.content_area{
    background:#ffffff; 
    padding:20px;  
    border: 1px dotted #bbbbbb;
    }

.tab_area div.select{
    /*アクティブタブの装飾*/
    background:#ffffff; 
    background-image:none; 
    color:#333333;
    border: 1px dotted #909090;
    border-top:3px solid #3f51b5;
    }
    

ul.sc_article{
    list-style: none;
    overflow:auto;
    padding: 0 5px;
}

ul.sc_article.grid{
    display: flex;
    flex-wrap: wrap;
}

ul.sc_article li{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 5px;
    position: relative;
}

ul.sc_article.col li {
    width: 32%;
    display: inline-block;
    margin: 0 .5%;
}


.content ul.sc_article a{
  text-decoration: none;
  display: block;
}


.sc_article_title{
    font-size: 0.9em;
    display: unset;
}

.sc_article_title a{
    color: #333;
}

.sc_article_title a:hover{
    color: #5eb0f1;
}

.sc_article_date{
    font-size: 0.9em;
    display: inline-block;
    min-width: 90px;
    margin-right: 5px;
}

.sc_article_cat {
    background: rgba(0,0,0,.7);
    color: #fff !important;
    padding: 4px 7px;
    font-size: 0.8em;
    border-radius: 1px;
    margin-right:8px;
}

.sc_article.simple .sc_article_cat{
    padding: 4px 7px !important;
    font-size: 0.8em !important;
    border-radius: 1px;
    margin-right: 8px !important;
    display: inline-block !important;
}

.sc_article.grid > a{
    width: calc(33.333% - 16px);
    display: inline-block;
    vertical-align: top;
    background: #fff;
}

.sc_article.grid > a,.sc_article.list > a {
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
  background-color: #fff;
  margin: 8px;
  transition: .4s;
}

.sc_article.grid li,.sc_article.list li {
    white-space: normal;
    overflow: auto;
    padding: 0;
    margin: 0;
    color: #000;
}

.sc_article.grid li .meta,.sc_article.list li .meta {
    padding: 8px;
    overflow: auto;
}

.sc_article.grid .post_list_thumb,.sc_article.list .post_list_thumb{
  position: relative;
  background-color: #eee;
}

.sc_article.list .post_list_thumb{
  float: left;
  width: 140px;
}

.sc_article.grid .post_list_thumb img {
    width: 100%;
    object-fit: cover;
    border: none;
    box-shadow: none;
    margin: 0;
}

.sc_article.list .post_list_thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
    box-shadow: none;
    margin: 0;
}

.sc_article.grid .post_list_thumb .sc_article_cat,.sc_article.list .post_list_thumb .sc_article_cat{
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
  font-weight: bold;
  font-size: .6em;
  padding: 3px 6px;
  background: rgba(0,0,0,.7);
}

.sc_article.grid .sc_article_title,.sc_article.list .sc_article_title {
    font-size: 14px;
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.sc_article.grid .sc_article_date,.sc_article.list .sc_article_date{
    font-size: 0.8em;
}

#sidebar .sc_article.grid > a {
    width: calc(50% - 12px);
    margin: 6px;
}

#sidebar .sc_article.grid .sc_article_title,#sidebar .sc_article.list .sc_article_title{
  font-size: 12px;
}

#sidebar .sc_article.list .post_list_thumb{
    width: 100px;
    height: 80px;
}

.sc_article.rank{
    counter-reset: article-ranking;
}

.sc_article.rank li:after{
    content: counter(article-ranking, decimal);
    counter-increment: article-ranking;
    line-height: 1;
    position: absolute;
    padding: 5px 10px;
    left: 0;
    top: 0;
    background: #313131;
    color: #fff;
    font-size: 1.1em;
    font-weight: bold;
}

.sc_article.rank a:nth-child(1) li:after{
    background: rgb(255, 230, 88);
}
.sc_article.rank a:nth-child(2) li:after{
    background: #ccc;
}
.sc_article.rank a:nth-child(3) li:after{
    background: rgba(255, 121, 37, 0.8);
}


@media screen and (max-width:959px){
  #sidebar .sc_article.grid > a {
    width: calc(33.3333% - 12px);
    margin: 6px;
  }
}

@media screen and (max-width:768px){
  ul.sc_article{
    padding:0px;
  }

  ul.sc_article li {
    white-space: unset;
  }

  ul.sc_article.col li {
    width: 49%;
  }

}

@media screen and (max-width:599px){
  ul.sc_article.col li {
    width: 100%;
  }

  #sidebar .sc_article.grid > a,.sc_article.grid > a {
    width: calc(50% - 12px);
    margin: 6px;
  }
}

.sc_qrcode{
  text-align: center;
}

.sc_content_icon,.sc_content_icon i{
  vertical-align: baseline;
  padding: 0 .2em;
  line-height: 1;
  display: inline-block;
}

.sc_content_icon i span {
    display: none;
}


@media screen and (max-width:768px){

    .sc_getpost a, .content .sc_getpost a{
      padding: 5px;
    }

    .sc_getpost{
      margin:.5em 0;
    }

    .sc_getpost_thumb{
        width: 100px;
    }

    .sc_getpost .title {
        font-size: 0.8em;
    }

    .sc_getpost .substr{
        display: none;
    }

    .sc_getpost .date{
        position: static !important;
        font-size: .6em;
    }

}

.sc_popup_content {
    background: #fff;
    padding: 1em;
    border-radius: 2px;
}


/****************************************

          user_list

*****************************************/

.user_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}
.user_list .user{
    padding: 10px 15px 20px;
    width: 20%;
    text-decoration: none;
    min-width: 130px;
}
.user_list .user_avator{
    width: 100%;
    margin-bottom: 5px;
}
.user_list .user_avator img {
    border-radius: 50%;
    object-fit: cover;
    margin: 0;
}

.user_list .user_name {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: .5px;
    color: #333;
}

@media screen and (max-width: 768px){
  .user_list .user{
    width: 25%;
  }
}
@media screen and (max-width: 599px){
  .user_list .user{
    width: 50%;
  }
}

/****************************************

          popular posts

*****************************************/

#sidebar .popular-posts ul {
    padding-left: 0;
    list-style: none;
    font-size: 14px;
    margin: -10px;
}

 /*******
.popular-posts li:nth-of-type(1):before {
    content: url('http://-----.jpg');
}
 
.popular-posts li:nth-of-type(2):before {
    content: url('http://-----.jpg');
}
 
.popular-posts li:nth-of-type(3):before {
    content: url('http://-----.jpg');
}

*******/

.wpp-list {
    counter-reset: wpp-ranking;
}

.diver_popular_posts{
    counter-reset: dpp-ranking;
}
 
/* 一覧の表示スタイル */
.popular-posts li {
    position: relative;
    overflow: hidden;
    float: none;
    clear: both;
    border-bottom: 1px solid #ccc;
    padding:8px !important; 
}

.wpp-thumbnail{
    object-fit: cover;
    font-family: 'object-fit:cover;';
    float: left;
    margin-right: 5px;
}

.wpp-post-title {
    font-size: .9em !important;
    margin-top: 5px;
}


.wpp-list li{
  position: relative;
}
.diver-tabwidget__content ul.wpp-list li a {
    display: initial;
    padding: 0;
}

.diver-tabwidget__content ul.wpp-list li{
  padding:8px; 
}

.post_list_views {
    font-size: .8em;
    margin-top: 5px;
}
/*.post_list_views:before {
    content: "\f06e";
    font-family: fontAwesome;
}*/
.post_list_views:after {
    content: "views";
    margin-left: 3px;
}



/****************************************

          plugin - popular posts

*****************************************/
.amazonjs_item .amazonjs_info h4:before,.amazonjs_item .amazonjs_info h4:after{
  content:none;
}
/****************************************

          contact

*****************************************/
.contact > div:after{
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: " ";
    font-size: 0;
}

.contact th {
    vertical-align: middle;
    font-size: 0.8em;
}

.contact td {
    padding: 5px 10px;
}

.contact .form_title {
    width: 20%;
    float: left;
}

.contact .form_area {
    float: right;
    width: 77%;
    margin-bottom: 10px;
}

.contact .form_area textarea{
  width: 100%;
}

.wpcf7 .form_title span {
    font-size: 12px;
    color: #fff;
    background-color: #f44336;
    padding: 3px 6px;
    border-radius: 3px;
    margin-left: 12px;
}

.wpcf7-form-control.wpcf7-submit {
    display: block;
    margin: 0 auto;
    padding: 1em 4em;
}

@media screen and (max-width:768px){
  .contact .form_title,.contact .form_area {
    width: 100%;
    float: none;
  }
}


/****************************************

          lazyload

*****************************************/
/*.lazyload, .lazyloading {
    opacity: 0;
}*/
/*.lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
}
*//****************************************

          biral sns

*****************************************/

.p-entry__push {
    margin-bottom: 10px;
    display: table;
    table-layout: fixed;
    width: 100%;
    background-color: #2b2b2b;
    color: #fff;
    background-size: cover;
    background-position: center;
}
.p-entry__pushLike {
    display: table-cell;
    padding: 60px 0;
    text-align: center;
    vertical-align: middle;
    line-height: 1.4;
    font-size: 20px;
    background-position: center;
    background-size: cover;
  position: relative;
    z-index: 0;
    overflow: hidden;    
}

.p-entry__pushLike:before{
  content: '';
    background: inherit;
    filter: brightness(60%) blur(5px);
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
}

.p-entry__pushButton {
    margin-top: 15px;
    display: inline-block;
    width: 200px;
    height: 40px;
    line-height: 40px;
}
.p-entry__pushButtonLike {
    line-height: 1
}
.p-entry__note {
    margin-top: 15px;
    font-size: 12px;
    color: #fff;
}
.p-entry__tw-follow {
    margin-bottom: 10px;
    background: #fff;
    width: 100%;
    padding: 15px 0
}
.p-entry__tw-follow__cont {
    text-align: center;
    font-size: 15px;
    color: #252525
}
.p-entry__tw-follow__item {
    display: inline-block;
    vertical-align: top;
    margin: 4px 15px
}

div .sidead{
width: 80%;
height:auto;
}

@media screen and (max-width: 768px){
  .p-entry__tw-follow{
    padding: 5px;
  }
  .p-entry__tw-follow__item{
    font-size: .9em;
  }
}

/******************************

 new label

******************************/

.newlabel{
  z-index: 20;
}

/******************************

 SNSボタン

******************************/
/* [fb_share] */

.fb_iframe_widget_fluid{
  display: inline-block !important;
}

.share-fb a {
    display: block;
    padding: 5px;
    background-color: #3B55A5;
    border-radius: 2px;
    color: #FFF;
    text-align: center;
    transition: opacity 0.35s;
    font-size:0.7em;
text-decoration: none;

}

.share-fb a:hover {
    color: #fff ;
    background-color: #243f77;
}

.share-fb span:before {
    white-space: pre ;
    content: '\A' ;
}

/* [Twitter] */

.sc-tw svg {
    width: 20px ;
    height: 20px ;
 }

.sc-tw a {
    margin: 0 auto ;
    padding: 5px ;
    display: block ;
    background: #1B95E0 ;
    font-size: 12px ;
    color: #fff ;
    font-weight: 700 ;
    text-decoration: none ;
    letter-spacing: .5px ;
    border-radius: 2px ;
}

.sc-tw a:hover {
    color: #fff ;
    background: #0c7abf ;
}

.sc-tw span:before {
    white-space: pre ;
    content: '\A' ;
}

/* [hatebu_share] */
.share-hatebu a {
    display: block;
    padding: 5px;
    background-color: #5d8ac1;
    border-radius: 2px;
    color: #FFF;
    text-align: center;
    transition: opacity 0.35s;
    font-size:0.7em;
text-decoration: none;

}

.share-hatebu a:hover {
    color: #fff ;
    background-color: #6d84b4;
}

/* [pocket_share] */
.share-pocket a {
    display: block;
    padding: 8px;
    background-color: #f03e51;
    border-radius: 2px;
    color: #FFF;
    text-align: center;
    transition: opacity 0.35s;
    font-size:0.7em;
    text-decoration: none;
}

.share-pocket a:hover {
    color: #fff ;
    background-color: #dc3749;
}

.share-pocket span:before {
    white-space: pre ;
    content: '\A' ;
}

.share-feedly a {
    display: block;
    padding: 8px;
    background-color: #87c040;
    border-radius: 2px;
    color: #FFF;
    text-align: center;
    transition: opacity 0.35s;
    font-size:0.7em;
    text-decoration: none;
}

.share-feedly a:hover {
    color: #fff ;
    background-color: #7baf3a;
}

.share-feedly span:before {
    white-space: pre ;
    content: '\A' ;
}





/* [Facebook] */
.sc-fb {
    z-index: 99 ;
    width: 65px ;
    margin-right: 9px !important
}

/* [Feedly] */

.feedly-count-box {
  width: 71px;
  text-align: center;
}

.feedly-count-box a {
  text-decoration: none;
}

.feedly-count-box span {
  text-decoration: none;
  display: block;
  color: #444;
  border: 1px solid #aaa;
  margin-bottom: 5px;
  font-size: 13px;
  padding: 6px;
  border-radius: 3px;
  position: relative;
  background: #fff;
}


.feedly-count-box span:before {
  position: absolute;
  top: 100%;
  left: 28px;
  width: 0;
  height: 0;
  content: "";
  border: 5px solid transparent;
  border-top-color: #999;
}

.feedly-count-box span:after {
  position: absolute;
  top: 100%;
  left: 29px;
  width: 0;
  height: 0;
  content: "";
  border: 4px solid transparent;
  border-top-color: #fff;
}

.feedly-count-box img:hover {
  opacity: 0.8;
}

/* [LINE] */
.sc-li {
    width:auto ;
}

.sc-li-img {
    border: none ;
    margin: 0 auto ;
    padding:0 ;
    width: 38px ;
    height: 62px ;
}

/* デスクトップPCではLINEボタンを表示しない */
@media screen and ( min-width:480px ) {
    .sc-li {
        display: none ;
    }
}

.twitter_btn{
  width: 74px;
}
 
.arrow_box {
  position: relative;
  background: #fff;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  text-align: center;
  font-size: 11px;
  height: 36px;
  line-height: 33px;
  display: block;
  text-decoration: none;
  color: #333;
  border-radius: 3px;
  font-family: "Hiragino Kaku Gothic ProN",Meiryo,sans-serif;
}
.arrow_box:after, .arrow_box:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
 
.arrow_box:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 3px;
  margin-left: -3px;
}
.arrow_box:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #ccc;
  border-width: 4px;
  margin-left: -4px;
}
 
/* サークルのスタイル */
.sns_circle{
    position:relative;
    width: 50px;
    height: 50px;
    margin-right:5px;
    border-radius: 15%;
    background-color: #999;
    float:left;
    list-style: none;
}
 
.sns_circle i{
    position:absolute;
    padding: 10px 10px 10px 12px;
    font-size:30px;
    color:#FFF;/* 文字の色 */
}
 
/* リンク領域をブロック全体に */
.sns_circle a{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    border-radius: 15%;
    text-indent:-999px;
    color:#FFF;/* 文字の色 */
}

.sns_circle.facebook{
    background-color: #265a96;
}
 
.sns_circle.twitter{
    background-color: #00acec;
}
.sns_circle.instagram{
    background-color: #ef7637;
}

 
/* マウスオーバー */
.sns_circle a:hover{
    background-color:#FFF;
    filter:alpha(opacity=50);
    -moz-opacity: 0.5;
    opacity: 0.5;
}

/****************************************

          カテゴリ最新記事

*****************************************/

.newpost_list {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(40%,1fr));
    gap: 10px;
}

.post_list_wrap {
    position: relative;
    width: 100%;
    vertical-align: top;
    letter-spacing: normal;
    a{
      display:flex;
      gap: 10px;
      width: 100%;
      text-decoration: none;
    }
    figure {
      background: #eee;
      width: 130px;
      float: left;
      overflow: hidden;
    }
    .meta{
      flex:1;
      overflow: hidden;
    }
    .date {
      font-size: .7em;
    }
    .title {
      font-size: .9em;
      overflow: hidden;
      font-weight: bold;
    }
    @include mq-down(md){
      a{
        flex-direction: column;
        figure{
          width: 100%;
        }
      }
    }
 
}



.post_list_wrap img {
    font-size: .8em;
}


.post_list .post_list_wrap {
    float: left;
    width: 25%;
    padding: 10px;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    min-height: 55px;
    position: relative;
}

.post_list .post_list_wrap+.post_list_wrap{
  border-bottom: 1px solid #eee;
    border-top: 0;
}

.post_list .post_list_wrap li {
    display: inline-block;
}



/****************************************

          pickup cat

*****************************************/

.pickup-cat-wrap{
    margin-bottom: 10px;
    overflow: hidden;
}

.pickup-cat-list{
    line-height: 1.3;
    position: relative;
    border-bottom: 1px solid #eee;
    a{
      padding:5px;
      display: flex;
      text-decoration: none;
    }
    .meta {
      flex:1;
      padding: 5px 10px;
      vertical-align: middle;
      overflow: hidden;
  }
  
}


.pickup-cat-img {
    position: relative;
    width: 120px;
    vertical-align: middle;
    background: #eee;
    overflow:hidden; 
    float: left;
}

.pickup-cat-img img {
    object-fit: cover;
    font-family: 'object-fit:cover;';
    width: 100%;
}

.pickup-cat-dt {
    font-size: 0.7em;
    color: #aaa;
    position: absolute;
    bottom: 5px;
    right: 10px;
}

.pickup-cat-excerpt{
  font-size: .7em;
}

.pickup-cat-title {
    font-size: 0.9em;
    line-height: 1.5;
    padding:.2em 0; 
}

@media screen and (max-width: 768px){
  .pickup-cat-excerpt{
    display: none;
  }

  .pickup-cat-title{
    font-size: .7em;
  }

  .pickup-cat-img{
    width: 90px;
  }

}
/****************************************

          sticky post

*****************************************/
.sticky-post-box{
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    cursor: pointer;
    transition: .4s;
    &:last-child{
      margin-bottom: 10px;
    }
    .post_thumbnail{
      width: 40%;
      float: none;
    }
    .post-meta-all{
      padding:10px 20px;
      line-height: 1.5;
      flex: 1;
    }
    .post-title {
      font-size: 1.2em;
      font-weight: bold;
      margin: 3px 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .post-date{
      font-size: 0.8em;
      padding: 0 .3em ;
    }
    .post-substr{
      font-size: 0.9em;
      margin-top: 10px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
    .post-cat{
      font-size: 0.8em;
      padding:5px 10px;
      border-radius: 3px;
      margin-right: 5px;
      background: #eee;
    }
    .post-tag{
      margin: 5px 0;
      .tag{
        display: inline-block;
        font-size: 0.7em;
        padding:3px 8px;
        background: #eee;
        margin-right: 5px;
      }
    }
}



@media screen and (max-width:768px){
  .sticky-post-box{
    display: block;
  }

  .sticky-post-box .post_thumbnail{
      display: block;
      width: 100%;
  }

  .sticky-post-box .post-meta-all{
      display: block;
      padding:10px 
  }

  .sticky-post-box .post-date{
    font-size: 0.8em;
  }
}

/******************************

  topに戻る

******************************/
#page-top {
    position: fixed;
    bottom: -100px;
    right: 20px;
    z-index: 998;
    a {
      background: #000;
      text-decoration: none;
      color: #fff;
      width: 50px;
      height: 50px;
      line-height: 48px;
      font-size: 18px;
      text-align: center;
      display: block;
      border-radius: 50%;
      &:hover{
        text-decoration: none;
        opacity: 0.7;
      }
    }
    @include mq-down(md){
      right: 10px;
      a{
        width: 42px;
        line-height: 42px;
        height: 42px;
        padding:0; 
        font-size: 16px;
      }
    }
}

/******************************

    CTA Widget

******************************/

.widget.containerwidget .cta_content figure img {
    width: 30%;
    float: left;
    padding: 20px;
}

.widget.widget_diver_widget_cta .wrap-post-title{
    background: none;
    text-align: center;
    color: #fff;
    font-size: 1.8em;
    border:none;
    border-radius: 0;
}

.widget.containerwidget .cta_content .content{
  font-size: 1.1em;
}

.widget.widget_diver_widget_cta .wrap-post-title:before,.widget.widget_diver_widget_cta .wrap-post-title:after {
    content: none;
}

@media screen and (max-width:768px){
  .widget.containerwidget .cta_content figure img {
    width: 100%;
    float: none;
    padding: 10px 0;
  }
  .widget.widget_diver_widget_cta .wrap-post-title {
      font-size: 1.2em;
      padding: 0em;
  }
  .widget.containerwidget .cta_content .content {
    font-size: .9em;
    padding: 10px;
  }

}

/******************************

    CTA

******************************/
#cta {
    background: #fff;
    margin-top: 10px;
}

.cta_content {
    padding: 10px 20px;
    overflow: auto;
}

.cta_thumbnail{
    width: 35%;
    float: right;
    text-align: center;
    padding: 10px;
    margin: 0 auto;
}

.cta_thumbnail img {
    height: auto;
    object-fit: contain;
    font-family: 'object-fit:contain;';
    max-height: 300px;
}

.cta_title {
    font-size: 27px;
    text-align: center;
    padding: 20px;
    background: #32454e;
    color: #fff;
    font-weight: bold;
}

.cta_btnarea {
    text-align: center;
    clear: both;
    padding-bottom: 20px;
}

.cta_btn {
    font-size: 23px;
    padding: 10px 60px;
}

.cta_content .content {
    font-size: 1em;
    line-height: 2;
    padding-top: 20px;
    margin-bottom: 0;
    background: none;
}

#cta .button.big a{
    width: 70%;
}

@media screen and (max-width:768px){
    .cta_thumbnail{
        float: none !important;
        width: 100% !important;
        margin-left: 0px !important; 
    }

    .cta_title{
        font-size: 18px;
        text-align: left;
    }

}


/****************************************

          LP

*****************************************/
#lp-wrap{
  width: 100%;
}

#lp-wrap .content{
  width: 1100px;
  margin: 0 auto;
}

#lp-wrap .lp_header_img{
  margin: 0 auto;
}

#lp-wrap .lp_header_img img{
  vertical-align: middle;
  width: 100%;
}

.lp_footer_content {
    padding: 10px 20px;
    font-weight: bold;
    text-align: center;
}

ul.lp-footer-nav-list{
  width: 100%;
}
ul.lp-footer-nav-list li,ul.lp-footer-nav-list li a {
    display: inline-block;
}
ul.lp-footer-nav-list li a {
    padding: 10px;
}

.lp-footer-copyrights {
    padding: 10px;
    letter-spacing: .05em;
    width: 100%;
}

@media screen and (min-width:1201px){
  #lp-wrap .content{
    width: 1100px;
  }
}

@media screen and (max-width:1200px){
  #lp-wrap .content{
    width: 90%;
  }
}

@media screen and (max-width:959px){

}

@media screen and (max-width:768px){
  #lp-wrap .content{
    width: 100%;
  }
}

/****************************************

          diver kiji

*****************************************/
.editer_diver_kiji {
    margin: 15px 0;
    border: 1px solid #eee;
}

.editer_diver_kiji_title {
    font-size: 0.9em;
    padding: 3px 10px;
    border-bottom: 1px solid #ccc;
}

.editer_diver_kiji_title:before{
    content: "\f0b2";
    margin-right: 10px;
    font-family: fontAwesome;
  }

ul.diver_rel_kiji {
    border-top: 1px solid #eee;
    padding: 0;
    list-style: none;
    margin-bottom: 0 !important;
}

ul.diver_rel_kiji li {
    line-height: 1.4;
    font-size: 0.8em;
    border-bottom: 1px solid #eee;
    padding: 5px 12px;
    margin-bottom: 0;
}

ul.diver_rel_kiji li a{
  text-decoration: none;
}

ul.diver_rel_kiji li:before {
    content: "\f0da";
    vertical-align: middle;
    margin-right: 10px;
    font-family: fontAwesome;
  }


/****************************************

        headline

*****************************************/
.diver_headline {
  margin: 0 auto;
  padding: 10px 0;
  font-size: 20px;
  color:#3cb3e4;
}
.diver_headline .diver_headline_text {
  margin: 10px 0
}
.diver_headline.border3 .diver_headline_text {
  color: #3cb3e4;
  text-align: center;
  overflow: hidden;
}
.diver_headline.border3 .diver_headline_text span {
  display: inline-block;
  position: relative;
}
.diver_headline.border3 .diver_headline_text span:before,
.diver_headline.border3 .diver_headline_text span:after {
  content: "";
  border-bottom: 2px solid #3cb3e4;
  width: 45px;
  margin: 0 15px;
  position: absolute;
  bottom: 50%;
}
.diver_headline.border3 .diver_headline_text span:before {
  right: 100%;
}
.diver_headline.border3 .diver_headline_text span:after {
  left: 100%;
}

/**********************************

        core columns

**********************************/


.content .wp-block-columns {
    display: flex;    
    justify-content: center;    
}

// .content .wp-block-column{
//     margin: 0 10px;
//     margin-bottom: 1em;
// }

@media (max-width: 769px){
  .content .wp-block-column{
    margin-right: 0;
    margin-left: 0;
    width: 50%;
    // padding: 0 .5em;
  }

  // .wp-block-columns.wp-block-column:nth-child(odd){
  //     padding-right: .5em;
  // }

  // .wp-block-columns.wp-block-column:nth-child(even){
  //     padding-left: .5em;
  // }

}

/**********************************

        core image

**********************************/

.wp-block-image figcaption{
    margin: 0;
}

/**********************************

        dvaux_iconbox

**********************************/

.dvaux_icon {
    max-width: 200px;
    margin: 0 auto;
    padding: 40px;
    margin-bottom: 1.2em;
}


/**********************************

        dvaux_iconbox

**********************************/


.dvaux_iconbox {
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 1em;
    position: relative;
}

.dvaux_iconbox_meta {
    padding: 0 1em 1em 1em;
}

.dvaux_iconbox .dvaux_icon {
    padding: 40px 0px;
    margin-bottom: 1em;
    max-width: 100%;
}


.dvaux_iconbox--icon{
    margin: 10px 0;
}

.dvaux_iconbox .title{
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    margin: 0 0 10px 0;
    padding: 0;
    background: none;
    color: #000;
    line-height: 1.5;
}

.dvaux_iconbox .textcontent{
  text-align: center;
  font-size: .9em;
}

.dvaux_iconbox .wp-block-image img{
  border:none;
}

.wp-block-column .dvaux_iconbox {
    height: 100%;
}

.wp-block-image img{
  margin-bottom: 0;
}


/**********************************

        dvaux_section

**********************************/

.dvaux_section_environ{
    margin: 0 -40px;
    padding-bottom: 60px;
    overflow: hidden;
}

.dvaux_section_environ{
  padding-bottom: 60px;
}

.dvaux_section_inner {
    padding: 40px;
    position: relative;
}

.dvaux_section_inner>*:not(.section_filter) {
    z-index: 2;
    position: relative;
    max-width: 1100px;
    margin: 0 auto;
  }

.dvaux_section_environ:not(.normal)+.dvaux_section_environ > .dvaux_section_inner{
  padding-top: 80px;
}

.dvaux_section_environ + .dvaux_section_environ {
    margin-top: -60px;
}

.dvaux_section_inner .section_filter {
    position: absolute;
    background-color: #000;
    opacity: .5;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.fullpage .dvaux_section_environ,
.full .dvaux_section_environ {
    width: 100vw;
    margin-left: calc(50% - 50vw);
}

.fullpage .dvaux_section_environ,
.full .dvaux_section_environ{
    padding-bottom: 5.5vw;
}
.fullpage .dvaux_section_environ+.dvaux_section_environ,
.full .dvaux_section_environ+.dvaux_section_environ {
    margin-top: -5.5vw;
}


.fullpage .dvaux_section_environ.slope1 .dvaux_section_inner::after,
.full .dvaux_section_environ.slope1 .dvaux_section_inner::after{
    bottom: -5.5vw;
}
.fullpage .dvaux_section_environ.slope1 .dvaux_section_inner::after,
.fullpage .dvaux_section_environ.slope2 .dvaux_section_inner::after,
.full .dvaux_section_environ.slope1 .dvaux_section_inner::after,
.full .dvaux_section_environ.slope2 .dvaux_section_inner::after {
    height: 5.5vw;
}

.fullpage .dvaux_section_environ[class*="slope"]+.dvaux_section_environ>.dvaux_section_inner,
.full .dvaux_section_environ[class*="slope"]+.dvaux_section_environ>.dvaux_section_inner {
    padding-top: 7vw;
}


@media screen and (max-width: 1200px){
  .dvaux_section_environ{
      margin: 0 -15px;
  }
  .dvaux_section_inner {
    padding: 40px 15px;
  }
}

@media screen and (max-width: 768px){
  .dvaux_section_environ{
      margin: 0 -10px;
  }
  .dvaux_section_inner {
    padding: 40px 10px;
  }
}

.dvaux_section_inner::after {
    position: absolute;
    content: '';
    pointer-events: none;
    z-index: 1;
}

.dvaux_section_environ.balloon .dvaux_section_inner::after{
    bottom: -35px;
    z-index: 10;
    background: inherit;
    left: 50%;    
    width: 70px;
    height: 70px;
    transform: translateX(-50%) rotate(45deg);
}

.dvaux_section_environ.slope1 .dvaux_section_inner::after{
    bottom: -50px;
    left: -5%;
    right: 0px;
    width: 110%;
    height: 20%;
    min-height: 60px;    
    background: inherit;
    transform-origin: left center;
    transform: rotate(-3deg);
}
.dvaux_section_environ.slope2 .dvaux_section_inner::after{
    bottom: 0px;
    left: -5%;
    right: 0px;
    width: 110%;
    height: 20%;
    min-height: 60px;    
    background: inherit;
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform: rotate(3deg);
}

.dvaux_section_environ.radius .dvaux_section_inner::after{
    bottom: -30px;
    left: 0;
    width: 100%;
    height: 60px;
    background: inherit;
    border-radius: 0 0 50% 50%;
}

.dvaux_section_environ.zigzag .dvaux_section_inner {
    padding-bottom: 60px;
    margin-bottom: -30px;
    position: relative;
    -webkit-mask-image: linear-gradient(0deg,transparent 30px,#fff 30px),linear-gradient(-135deg,#fff 15px,transparent 15px),linear-gradient(135deg,#fff 15px,transparent 15px);
    -webkit-mask-position: left bottom;
    -webkit-mask-repeat: repeat-x;
    -webkit-mask-size: 100% 100%,30px 30px,30px 30px;
    z-index: 1;
}

#lp-wrap  .dvaux_section_environ{
    padding-bottom: 5.5vw;
}
#lp-wrap .dvaux_section_environ+.dvaux_section_environ {
    margin-top: -5.5vw;
}

#lp-wrap .dvaux_section_environ.slope1 .dvaux_section_inner::after{
    bottom: -5.5vw;
}
#lp-wrap .dvaux_section_environ.slope1 .dvaux_section_inner::after,
#lp-wrap .dvaux_section_environ.slope2 .dvaux_section_inner::after {
    height: 5.5vw;
}

#lp-wrap .dvaux_section_environ[class*="slope"]+.dvaux_section_environ>.dvaux_section_inner {
    padding-top: 7vw;
}


/****************************************

         password form

*****************************************/

form.post_password{
  position: relative;
}

.diver_password_wrap {
    padding: 30px;
    margin: 30px 10px;
    box-shadow: 0 0px 3px rgba(0,0,0,.1);
    background: #fff;
}

.diver_password_title {
    font-size: 1.1em;
    color: #FF9800;
}

.diver_password_text {
    font-size: .9em;
    padding: 10px 0;
}

.diver_password_wrap input{
    display: inline-block;
}

.diver_password_wrap input[type="password"]{
    width: 100%;
    padding: 1em;
    padding-right: 165px;
    background-color:#fff;
    border-radius: 0;
}

.diver_password_wrap input[type="submit"]{
    width: 150px;
    border-radius: 0;
    height: 100%;
    position: absolute;
    right: 0;
    padding: 0;
}

@media screen and (max-width:768px){
  .diver_password_wrap input[type="password"]{
    padding: 1em;
  }
  .diver_password_wrap input[type="submit"]{
    width: 100%;
    position: relative;
    padding: .5em;
  }
}


/****************************************

          firstview

*****************************************/

.diver_firstview_simple{
  min-height: 200px;
  position: relative;
}

.firstview_content {
    width: 75%;
    height: 100%;
    margin: 0 auto;
    padding: 2em 0;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.diver_firstview_simple.stripe.length{
  background-image: linear-gradient(
    -45deg,
    transparent 25%,
    #ffc0cb 25%, #ffc0cb 50%,
    transparent 50%, transparent 75%,
    #ffc0cb 75%, #ffc0cb
  );
  background-size: 50px 50px;
}

.diver_firstview_image{
  position: relative;
}

.diver_firstview_image.norepeat{
  background-repeat: no-repeat;
}

.diver_firstview_mov{
  position: relative;
  width: 100%;
  overflow: hidden;
  background-size: 100% auto;
  background-position: center;
  background-color: #000; 
}
@media screen and (max-width:768px){
  .diver_firstview_mov{
    background-size: auto 100%;
    background-position: center;
  }
}

.diver_firstview_mov.full{  
  height: 100vh;
}

.firstview_video_wrap{
    width: 100%;
    height: 100%;
    position: relative;
    background: #000;
}

.diver_firstview_mov.auto .firstview_video_wrap{
  position: absolute;
}

.diver_firstview_mov video{
  vertical-align: middle;
  min-width: 100%;
  min-height: 100%;
  font-family: 'object-fit:cover;';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.diver_firstview_mov .firstview_video_wrap{
height: 100%;
}

.diver_firstview_mov.full .firstview_content,.diver_firstview_mov.custom .firstview_content{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.diver_firstview_mov.auto #diver_firstview_ytplayer{
  position: absolute;
}

.diver_firstview_mov #diver_firstview_ytplayer{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.diver_firstview_mov_cover{
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%;
  height: 100%;
}

.firstview_video_wrap .diver_firstview_mov_cover.dark,.diver_firstview_mov_cover.dark .YTPOverlay {
    background: rgba(0,0,0,0.3);
}

.firstview_video_wrap .diver_firstview_mov_cover.dot,.diver_firstview_mov_cover.dot .YTPOverlay{
    background-image: radial-gradient(rgba(0, 0, 0, 0.5) 22%, transparent 0), radial-gradient(rgba(0, 0, 0, 0.5) 22%, transparent 0);
    background-position: 0px 0px, 4px 4px;
    background-size: 8px 8px;
  }

.firstview_video_wrap .diver_firstview_mov_cover.check,.diver_firstview_mov_cover.check .YTPOverlay{
  background-image: linear-gradient(transparent 80%, rgba(0, 0, 0, 0.1) 80%, rgba(0, 0, 0, 0.1) 100%),
                    liner-gradient(90deg, transparent 80%, rgba(0, 0, 0, 0.1) 80%, rgba(0, 0, 0, 0.1) 100%);
  background-color: transparent;
  background-size: 10px 10px;
}

.firstview_video_wrap .diver_firstview_mov_cover.pattern,.diver_firstview_mov_cover.pattern .YTPOverlay{
  background: radial-gradient(closest-side, rgba(0,0,0,0) 98%, rgba(0,0,0,0.298039) 99%), 
              radial-gradient(closest-side, rgba(0,0,0,0) 98%, rgba(0,0,0,0.298039) 99%), rgba(90,90,90,0.7);
  background-position: 0 0, 40px 40px;
  background-size: 80px 80px;
}

@media screen and (max-width:768px){
  .firstview_content{
    width: 90%;
  }
}

/****************************************

          other style

*****************************************/

.hvr-fade-post {
    transition-duration: 0.4s;
}

.sns.mini .fb_iframe_widget{
  top:-5px;
}

#marquee {
  width: 100%;
  overflow: hidden;
}

.notfound_message{
  letter-spacing: normal;
}

i span {
    display: none;
}

@media screen and (max-width: 600px){
  #wpadminbar {
      position: fixed;
  }
}

.content i span {
    display: none;
}

ins.adsbygoogle {
    position: relative;
    z-index: 999;
}

@media screen and (max-width: 599px){
  .sp_hide{
    display: none;
  }

  .instagram-media {
    min-width: auto !important;
  }
}

/****************************************

          tab widget

*****************************************/


.diver-tabwidget__content ul li a{
  display: block;
  padding: .8em 1em;
  margin: 0;
  position: relative;
  font-size: .9em;
}

#main .diver-tabwidget__content ul li a{
    font-size: 1em;
}

.diver-tabwidget__content ul li.widget_post_list a{
  padding: 8px;
}

/****************************************

          header message

*****************************************/

.header_message {
    background: #eee;
    text-align: center;
    font-weight: bold;
    position: relative;
}

.header_message_badge {
    display: inline-block;
    background: #f00;
    color: #fff;
    padding: .4em .6em;
    border-radius: 3px;
    font-size: .7em;
    margin-right: 8px;
    vertical-align: middle;
}

.header_message_text {
    display: inline-block;
    vertical-align: middle;
    font-size: .9em;
    transition: .4s;
}

.header_message .header_message_wrap {
    padding: 10px;
    display: block;

}

a.header_message_wrap:hover .header_message_text{
  opacity: .7;
}

@media screen and (max-width:768px){
  .header_message{
    font-size: .9em;
  }

  .header_message_text {
    width: 100%;
  }
}

/****************************************

          nav_in_btn

*****************************************/

.nav_in_btn {
    font-weight: bold;
}

.nav_in_btn ul{
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
}
.nav_in_btn ul li {
    border-left: solid 1px #d4d4d4;
}
.nav_in_btn ul li:last-child {
    border-right: 1px solid #d4d4d4;
}
.nav_in_btn ul li a{
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 85px;
    font-size: 12px;
    font-weight: bold;
}

.nav_in_btn ul li a:hover {
    opacity: .7;
}

.nav_in_btn ul li i {
    font-size: 30px;
    margin: 5px 0;
}

@media screen and (max-width:959px){
  .nav_in_btn {
    display: none;
  }
}

@media screen and (max-width:599px){
  .d_sp .nav_in_btn {
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
    display: block;
  }

  .d_sp .nav_in_btn ul li a{
    width: 100%;
    height: 65px;
    font-size: 10px;
  }

  .d_sp .nav_in_btn ul li{
    width: 25%;
  }

  .d_sp .nav_in_btn ul li i {
    font-size: 20px ;
  }
}

/****************************************

          fb comment

*****************************************/

.fb-comments{
  width: 100%;
}

.fb-comments iframe {
    min-width: 100%;
}

/****************************************

          hover anime

*****************************************/

@media screen and (min-width: 600px){
  .post_list_wrap:hover,.mini-list-post-box:hover .post-box-contents,.pickup-cat-list:hover,.single-recommend:hover,.widget_post_list:hover,.sticky-post-box:hover,.sc_article.list > a:hover{
      transform: translate(3px,0);
  }

  .grid_post-box:hover .post-box-contents,.post_footer_author .author-post:hover,.widget_post_list.grid:hover,.sc_article.grid > a:hover{
    transform: translate(0,-3px);
  }
}

/****************************************

    effect

*****************************************/

.fa:before{
  font-family: fontAwesome;
}

/****************************************

    widget_block

*****************************************/

.widget.widget_block h2,
.widget.widget_block h3{
  position: relative;
  font-size: 16px;
  padding: 8px 15px;
  margin: 10px -10px;
}

.widget.widget_block .wp-block-group h2:first-child,
.widget.widget_block .wp-block-group h3:first-child{
  margin: -10px;
  margin-bottom: 10px;
}

.wp-block-search .wp-block-search__input{
  background: #fff;
  height: 38px;
  border: none;
}

.wp-block-search .wp-block-search__button{
  background: #3c74ad;
  color: #fff;
  border-radius: 0;
  font-weight: bold;
  white-space: nowrap;
}

.wp-block-search .wp-block-search__button svg{
  fill: #fff;
}

.wp-block-search.wp-block-search__button-inside .wp-block-search__inside-wrapper .wp-block-search__input{
  border-radius: 0;
  border: none;
  padding: 0 0 0 .25em;
}

.wp-block-search:not(.wp-block-search__button-outside) .wp-block-search__inside-wrapper,
.wp-block-search.wp-block-search__button-outside .wp-block-search__input{
  border: 1px solid #ccc;
}

